// let dev = false;
let url = "";
// const hostname = window.location.href;
// console.log('Hostname:', hostname);
const protocol = window.location.protocol;
const hostname = window.location.hostname;
const port = window.location.port;

// Construct the base URL
const hostname1 = `${protocol}//${hostname}${port ? `:${port}` : ''}`;

console.log('Base URL:', hostname1);
export let url_upload = "";

if (hostname1 === 'http://localhost:3000') {
  url = "http://localhost:3001";
  url_upload = "http://localhost:3001/uploads";
} else if (hostname1 === 'https://www.oilctm.com') {
  url = "https://oilctm.com:443/backend";
  url_upload = "https://oilctm.com:443/uploads";
} else {
  url = "https://oilctm.com:443/backend";
  url_upload = "https://oilctm.com:443/uploads";
}

export default url;