import React, { useState } from "react";
import "./UserJobs.css";
import Paper from "../../../components/whiteCard/whiteCard";
import ContainerHeader from "../../../components/containerHeader/ContainerHeader";
import Button from "../../../components/inputComponents/Button";
import DropDown from "../../../components/inputComponents/DropDown";
import * as requests from "../../../api/requests";
import MaterialTables from "../../../components/materialTable/MaterialTables";
import Input from "../../../components/inputComponents/Input";
import Modal from "../../../components/ui/modal/Modal";
import Loader from "../../../components/spinner/Spinner";
import url, {url_upload} from "../../../constants/url";

const UserJobs = () => {
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [userOp, setUserOp] = useState([
    {
      _id: null,
      name: "Select",
    },
  ]);
  const [user, setUser] = useState([]);
  const [dates, setDates] = useState({
    from: "",
    to: "",
  });
  const [tableContents, setTableContents] = useState(null);
  const [tableTitle, setTableTitle] = useState('')
  const connLinks = [
    {
      name: "All Reports",
      path: '/reports'
    }
  ]
  const headings = [
    "Project Type",
    "Project Id",
    "Zone",
    "Area",
    "PO Number",
    "PR Number",
    "Notification Number",
    "State",
    "DOS",
    "DOC",
    "Subcontractor",
    "Technician",
    "Vendor",
    "Drawing",
    "Estimate",
  ];

  React.useEffect(() => {
    setLoading(true);
    setShow(true);
    requests
      .get({ url: "/users/getAllUsers" })
      .then((data) => {
        let tempUsers = [
          {
            _id: null,
            name: "Select",
          },
        ];
        data.map((user) => {
          tempUsers.push({
            _id: user._id,
            name: user.name,
          });
        });
        setUserOp(tempUsers);
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  function handleChange(event) {
    const { name, value } = event.target;
    setDates((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  function getUserJobs() {
    setLoading(true);
    setShow(true);
    requests
      .post({
        url: "/projects/getUserProjects",
        body: { userId: user },
      })
      .then((data) => {
        let tempProjectList = [];
        console.log(data)
        data.project.map((project) => {
          let tempFrom = new Date(dates.from);
          let tempTo = new Date(dates.to);
          let tempDOS = new Date(project.dos);
          let tempDOC = new Date(project.doc);
          if (
            tempFrom.getTime() <= tempDOS.getTime() &&
            tempTo.getTime() >= tempDOC.getTime()
          ) {
            tempProjectList.push({
              "Project Type": project.projectType,
              "Project Id": project.projectId,
              Zone: project.zone ? project.zone.zoneName : "",
              Area: project.area ? project.area.areaName : "",
              "PO Number": project.poNumber,
              "PR Number": project.prNumber,
              "Notification Number": project.notificationNo,
              State: project.state,
              DOS: new Date(project.dos).toLocaleDateString("en-GB"),
              DOC: new Date(project.doc).toLocaleDateString("en-GB"),
              Subcontractor: project.subcontractorName ? "Yes" : "No",
              Technician: project.technician ? project.technician.name : null,
              Vendor: project.vendor ? project.vendor.name : null,
              Drawing:
                project.drawing === "noimage.jpg" ? (
                  "no-uploads"
                ) : (
                  <a
                    href={`${url_upload}/projects/drawing/${project.drawing}`}
                    target="_blank"
                  >
                    View Drawing
                  </a>
                ),
              Estimate:
                project.estimate === "0" ? (
                  "0"
                ) : (
                  <a
                    href={`${url_upload}/projects/estimate/${project.estimate}`}
                    target="_blank"
                  >
                    View Estimate
                  </a>
                ),
            });
          }
        });
        setTableContents(tempProjectList);
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });
  }

  const modalClose = () => {
    setShow(false);
  };

  const settitle = (e) => {
    userOp.forEach(user => {
      if( user._id === e.target.value) 
        setTableTitle(user.name)
    });
  }

  return (
    <div id="user-jobs">
      {loading || error ? (
        <Modal show={show} noHandler={modalClose}>
          {error ? <p>Something went wrong!</p> : <Loader></Loader>}
        </Modal>
      ) : null}
      <ContainerHeader formName="User Jobs List" connLinks={connLinks}> </ContainerHeader>
      <Paper>
        <div className="user-jobs-form">
          <div className="fields">
            <div className="field">
              <DropDown
                label="Select User"
                name="user"
                size="wide"
                options={userOp}
                onChange={(e) => {
                  setUser(e.target.value)
                  settitle(e)
                }}
              />
            </div>
            <div className="field">
              <Input
                type="date"
                name="from"
                size="wide"
                label="From DOS"
                onChange={handleChange}
              />
            </div>
            <div className="field">
              <Input
                type="date"
                name="to"
                size="wide"
                label="To DOC"
                onChange={handleChange}
              />
            </div>
          </div>
          <br />
          <hr />
          <Button label="View Jobs" className="success" onClick={getUserJobs} />
        </div>
        <br />
        <br />
        {tableContents && tableContents.length > 0 ? (
          <MaterialTables headings={headings} data={tableContents} title={`${tableTitle} Job List`}/>
        ) : (
          "No projects"
        )}
      </Paper>
    </div>
  );
};

export default UserJobs;
