import React, { useState, useEffect } from "react";
import * as requests from "../../../api/requests";
import ContainerHeader from "../../../components/containerHeader/ContainerHeader";
import Paper from "../../../components/whiteCard/whiteCard";
import MaterialTables from "../../../components/materialTable/MaterialTables";
import Modal from "../../../components/ui/modal/Modal";
import Loader from "../../../components/spinner/Spinner";
import authCheck from "../../../helpers/auth.helpers";

const HaltedProjectsList = () => {
  // const { areaAndZone, designation } = authCheck(localStorage.getItem('authCode'));
  const { designation } = localStorage;
  const  areaAndZone  = JSON.parse(localStorage.getItem("areaAndZone"))

  const [tableContents, setTableContents] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const connLinks = [
    {
      name: "All Reports",
      path: '/reports'
    }
  ]
  const headings = [
    "Project Type",
    "Project Id",
    "Zone",
    "Area",
    "PO Number",
    "PR Number",
    "Notification Number",
    "Subcontractor",
    "Subcontractor Name",
    "Technician",
    "Vendor",
  ];
  useEffect(() => {
    setLoading(true);
    setShow(true);
    requests
      .get({ url: "/projects/getProjectList" })
      .then((data) => {
        let tempProjectList = [];
        let areaList = []
        areaAndZone.map(area => {
          areaList.push(area.area._id)
        })
        data.map((project) => {
          if (project.state === "Halt" && (designation == "Engineer" || designation == "GM/DGM" ? areaList.includes(project.area._id) : true)) {
            tempProjectList.push({
              "Project Type": project.projectType,
              "Project Id": project.projectId,
              Zone: project.zone ? project.zone.zoneName : "",
              Area: project.area ? project.area.areaName : "",
              "PO Number": project.poNumber,
              "PR Number": project.prNumber,
              "Notification Number": project.notificationNo,
              Subcontractor: project.subcontractorName ? "Yes" : "No",
              "Subcontractor Name": project.subcontractorName ? project.subcontractorName : "-",
              Technician: project.technician ? project.technician.name : null,
              Vendor: project.vendor ? project.vendor.name : null,
            });
          }
        });
        setTableContents(tempProjectList);
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });
  }, []);
  const modalClose = () => {
    setShow(false);
  };
  return (
    <>
      {loading || error ? (
        <Modal show={show} noHandler={modalClose}>
          {error ? <p>Something went wrong!</p> : <Loader></Loader>}
        </Modal>
      ) : null}
      <ContainerHeader formName="Halted Projects List" connLinks={connLinks}></ContainerHeader>
      <Paper>
        <div className="project-list-table">
          {tableContents && tableContents.length > 0 ? (
            <MaterialTables
              headings={headings}
              data={tableContents}
              title="Halted Projects"
            />
          ) : (
              "No projects"
            )}
        </div>
      </Paper>
    </>
  );
};

export default HaltedProjectsList;
