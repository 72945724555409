import React, { useState, useEffect } from "react";
import Paper from "../../../components/whiteCard/whiteCard";
import Axios from "axios";
import MaterialTables from "../../../components/materialTable/MaterialTables";
import { Link } from "react-router-dom";
import IconButton from "../../../components/inputComponents/IconButton";
import url from "../../../constants/url";
import Modal from "../../../components/ui/modal/Modal";
import Loader from "../../../components/spinner/Spinner";
import ContainerHeader from "../../../components/containerHeader/ContainerHeader";
import authCheck from "../../../helpers/auth.helpers";

const ProjectsShowCause = () => {
  // const { areaAndZone, designation } = authCheck(localStorage.getItem('authCode'));
  const { designation } = localStorage;
  const  areaAndZone  = JSON.parse(localStorage.getItem("areaAndZone"))
  
  const [tableContents, setTableContents] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const connLinks = [
    {
      name: "All Reports",
      path: '/reports'
    }
  ]
  const headings = [
    "Project Type",
    "Project Id",
    "Zone",
    "Area",
    "PO Number",
    "PR Number",
    "Notification Number",
    "Subcontractor",
    "Technician",
    "Vendor",
    "View Show Cause Notice",
  ];

  useEffect(() => {
    setLoading(true);
    setShow(true);
    let tempArr = [];
    Axios.get(url + "/projects/getProjectList")
      .then((response) => {
        console.log(response)
        let areaList = []
        areaAndZone.map(area=>{
          areaList.push(area.area._id)
        })
        response.data.map((obj) => {
          if (obj.showcause && (designation=="Engineer"||designation=="GM/DGM"?areaList.includes(obj.area._id):true)) {
            let tempObj = {
              "Project Type": obj.projectType,
              "Project Id": obj.projectId,
              Zone: obj.zone ? obj.zone.zoneName : "",
              Area: obj.area ? obj.area.areaName : "",
              "PO Number": obj.poNumber,
              "PR Number": obj.prNumber,
              "Notification Number": obj.notificationNo,
              Subcontractor: obj.subcontractorName ? "Yes" : "No",
              Technician: obj.technician ? obj.technician.name : null,
              Vendor: obj.vendor ? obj.vendor.name : null,
              "View Show Cause Notice": (
                <div>
                  <Link to={"/show-cause/" + obj._id}>
                    <IconButton
                      className="success"
                      hint="View show cause"
                      iconClass="fa fa-sticky-note-o"
                    ></IconButton>
                  </Link>
                </div>
              ),
            };
            tempArr = [...tempArr, tempObj];
          }
        });
        setTableContents(tempArr);
        modalClose();
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  const modalClose = () => {
    setShow(false);
  };

  return (
    <div className="projectList">
      {loading || error ? (
        <Modal show={show} noHandler={modalClose}>
          {error ? <p>Something went wrong!</p> : <Loader></Loader>}
        </Modal>
      ) : null}
      <ContainerHeader formName="Project List (Show Cause Notice Issued)" connLinks={connLinks}></ContainerHeader>
      <Paper>
        <MaterialTables data={tableContents} headings={headings} title="Projects (Show Cause Issued)"/>
      </Paper>
    </div>
  );
};

export default ProjectsShowCause;
