const jwt = require('jsonwebtoken');
var authDetails;

function authCheck(token){
    // console.log('REACT_APP_USER_SIGN:', process.env.REACT_APP_USER_SIGN);
    jwt.verify(token, process.env.REACT_APP_USER_SIGN, function(err, decoded) {
        if(err){
            console.log("Error", err);
            authDetails = {};
        } else {
            authDetails = decoded
            console.log(authDetails)
        }
    })
    return authDetails;
}

module.exports = authCheck;
// const jwt = require('jsonwebtoken');

// function authCheck(token) {
//     try {
//         const decoded = jwt.verify(token, process.env.REACT_APP_USER_SIGN);
//         return decoded;
//     } catch (error) {
//         console.error("JWT Verification Error:", error.message);
//         return null; // or throw an error depending on your application logic
//     }
// }

// module.exports = authCheck;