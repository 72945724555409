import React, { useState, useEffect } from "react";
import "./AddNotification.css";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Whitecard from "../../components/whiteCard/whiteCard";
import Input from "../../components/inputComponents/Input";
import DropDown from "../../components/inputComponents/DropDown";
import Button from "../../components/inputComponents/Button";
import TextArea from "../../components/inputComponents/TextArea";
import Axios from "axios";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import url from "../../constants/url";
import Alert from "@material-ui/lab/Alert";

const authCheck = require("../../helpers/auth.helpers");
const sort = require("../../helpers/item.sort");

const NotificationListing = (props) => {

  // const { areaAndZone } = authCheck(localStorage.getItem('authCode'));
  const  areaAndZone  = JSON.parse(localStorage.getItem("areaAndZone"))

  const [form, setform] = useState({});
  const [data, setdata] = useState([]);
  const [areaOp, setareaOp] = useState([]);
  const [installOp, setinstallOp] = useState([]);
  const [show, setShow] = useState(false);
  const [functionToPass, setFunctionToPass] = useState(null);
  const [ques, setQues] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [file, setFile] = useState();

  const projectTypeOp = [
    {
      _id: "Select Type",
      name: "Select Type",
    },
    {
      _id: "Contract",
      name: "Contract",
    },
    {
      _id: "Miscellaneous",
      name: "Miscellaneous",
    },
    {
      _id: "Other",
      name: "Other",
    },
  ];

  useEffect(() => {
    setLoading(true);
    setShow(true);
    Axios.get(url + "/areas/getAreas")
      .then((result) => {
        let dataArray = [];
        let areaArray = [];
        result.data.map((area) => {
          dataArray.push({
            _id: area._id,
            zones: area.zones,
            areaName: area.areaName,
            installations: area.installations,
          });
          areaArray.push({
            _id: area._id,
            name: area.areaName,
          });
        });
        setdata(dataArray);
        let userAreaList = [];
        areaAndZone.map((area) => {
          userAreaList.push(area.area._id);
        });
        let newAreaArray = areaArray.filter((area) => {
          return userAreaList.includes(area._id);
        });
        sort(newAreaArray, "name");
        newAreaArray.unshift({ _id: "Select", name: "Select" });

        setareaOp(newAreaArray);
        modalClose();
        setLoading(false);
      })
      .catch((error) => {
        console.log("Error", error);

        setError(true);
        setLoading(false);
      });
  }, []);

  const modalVisible = () => {
    setError(false);
    let errors = [];
    let toCheck = {};
    toCheck = {
      projectType: "PROJECT TYPE",
      notificationNumber: "NOTIFICATION NUMBER",
      date: "DATE OF NOTIFICATION",
      area: "AREA",
      installation: "INSTALLATION",
      description: "DESCRIPTION",
    };
    Object.keys(toCheck).map((key) => {
      if (!form[key]) {
        errors.push(toCheck[key] + " is required");
      }
    });
    if (errors.length > 0) {
      setFormErrors(errors);
      return;
    }
    setFormErrors([]);
    setFunctionToPass(() => () => onClickHandler());
    setQues("Do you want to add this notification?");
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };

  const onClickHandler = () => {
    setLoading(true);

    const data = new FormData();
    data.append("area", form.area);
    data.append("installation", form.installation);
    data.append("projectType", form.projectType);
    data.append("notificationNumber", form.notificationNumber);
    data.append("projectId", " ");
    data.append("date", form.date);
    data.append("description", form.description);
    data.append("nameRequisitioner", form.nameRequisitioner);
    data.append("departmentRequisitioner", form.departmentRequisitioner);
    data.append("phNumberRequisitioner", form.phNumberRequisitioner);
    data.append("isjob", false);
    data.append("file", file);

    Axios.post(url + "/notifications/createNotification", data)
      .then((data) => {
        setform({});
        setLoading(false);
        modalClose();
        props.history.push("/notifications");
      })
      .catch((e) => {
        setLoading(false);
        setError(true);
        setLoading(false);
      });
  };

  const selectInstallation = (event) => {
    data.map((area) => {
      if (area._id === event.target.value) {
        let arr = [];
        arr = area.installations;
        sort(arr, "name");

        arr.unshift({ name: "Select", _id: "Select" });
        setinstallOp(area.installations);
      }
    });
    let formTemp = form;
    formTemp = { ...formTemp, area: event.target.value };
    setform(formTemp);
  };
  return (
    <div>
      <Modal show={show} noHandler={modalClose}>
        {!loading ? (
          !error ? (
            <ConfirmContent
              yesHandler={functionToPass}
              noHandler={modalClose}
              question={ques}
            ></ConfirmContent>
          ) : (
            <p>Something went wrong!</p>
          )
        ) : (
          <Loader></Loader>
        )}
      </Modal>
      <ContainerHeader formName="Add Notification"></ContainerHeader>
      <Whitecard>
        <div id="an-col">
          <div className="an-row">
            <div className="an-row-in">
              {" "}
              <DropDown
                label="Project Type *"
                size="wide"
                options={projectTypeOp}
                onChange={(e) => {
                  let formTemp = form;
                  formTemp = { ...formTemp, projectType: e.target.value };
                  setform(formTemp);
                }}
              />
            </div>
            <div className="an-row-in">
              {" "}
              <Input
                name="notification-no"
                label="Notification No/Contract No *"
                placeholder="Enter No."
                size="wide"
                onChange={(e) => {
                  let formTemp = form;
                  formTemp = {
                    ...formTemp,
                    notificationNumber: e.target.value,
                  };
                  setform(formTemp);
                }}
              />
            </div>
            {/* <div className="an-row-in">
              {" "}
              <Input
                name="upload notification"
                type="file"
                label="Upload Notification/Contract"
                placeholder="Enter name"
                size="wide"
                onChange={(event) => {
                  const file = event.target.files[0];
                  setFile(file);
                }}
              />
            </div> */}
          </div>
          <div className="an-row">
            <div className="an-row-in">
              {" "}
              <Input
                name="date"
                label="Date Of Notification *"
                placeholder="Enter Date"
                size="wide"
                type="date"
                onChange={(e) => {
                  let formTemp = form;
                  formTemp = { ...formTemp, date: e.target.value };
                  setform(formTemp);
                }}
              />
            </div>
            <div className="an-row-in">
              {" "}
              <DropDown
                label="Area *"
                size="wide"
                options={areaOp}
                onChange={selectInstallation}
              />
            </div>
            <div className="an-row-in">
              {" "}
              <DropDown
                label="Installation *"
                size="wide"
                options={installOp}
                onChange={(e) => {
                  let formTemp = form;
                  formTemp = { ...formTemp, installation: e.target.value };
                  setform(formTemp);
                }}
              />
            </div>
          </div>
          {/* <div className="an-row">
            <div className="an-row-in">
              {" "}
              <Input
                name="req-name"
                label="Name of Requisitioner"
                placeholder="Enter Name"
                size="wide"
                onChange={(e) => {
                  let formTemp = form;
                  formTemp = { ...formTemp, nameRequisitioner: e.target.value };
                  setform(formTemp);
                }}
              />
            </div>
            <div className="an-row-in">
              {" "}
              <Input
                name="req-department"
                label="Requisitioner Department"
                placeholder="Enter Department"
                size="wide"
                onChange={(e) => {
                  let formTemp = form;
                  formTemp = {
                    ...formTemp,
                    departmentRequisitioner: e.target.value,
                  };
                  setform(formTemp);
                }}
              />
            </div>
            <div className="an-row-in">
              {" "}
              <Input
                name="req-no"
                label="Phone No. of Requisitioner"
                placeholder="Enter Number"
                size="wide"
                onChange={(e) => {
                  let formTemp = form;
                  formTemp = {
                    ...formTemp,
                    phNumberRequisitioner: e.target.value,
                  };
                  setform(formTemp);
                }}
              />
            </div>
          </div> */}
          <div className = "an-row">
            <div className="an-row-in">
              <TextArea
                rows="200px"
                cols="10"
                label="Project Description *"
                size="wide"
                onChange={(e) => {
                  let formTemp = form;
                  formTemp = { ...formTemp, description: e.target.value };
                  setform(formTemp);
                }}
              />
            </div>
          </div>
          {formErrors.map((error) => {
            return <Alert severity="info">{error}</Alert>;
          })}
          <div className="an-row-2">
            <Button className="success" label="Submit" onClick={modalVisible} />
          </div>
        </div>
      </Whitecard>
    </div>
  );
};

export default NotificationListing;
