import React, { useState } from "react";
import ContainerHeader from "../../../components/containerHeader/ContainerHeader";
import Paper from "../../../components/whiteCard/whiteCard";
import * as requests from "../../../api/requests";
import MaterialTables from "../../../components/materialTable/MaterialTables";
import Modal from "../../../components/ui/modal/Modal";
import Loader from "../../../components/spinner/Spinner";
import DropDown from "../../../components/inputComponents/DropDown";
import Button from "../../../components/inputComponents/Button";
import authCheck from "../../../helpers/auth.helpers";

const CumulativeMaterialJob = () => {
  // const { areaAndZone, designation } = authCheck(localStorage.getItem('authCode'));
  const { designation } = localStorage;
  const  areaAndZone  = JSON.parse(localStorage.getItem("areaAndZone"))

  const [tableContents, setTableContents] = React.useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [projectId, setProjectId] = useState("");
  const [options, setOptions] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [id, setId] = useState("");
  const [vendor, setVendor] = useState("");
  const connLinks = [
    {
      name: "All Reports",
      path: "/reports",
    },
  ];
  const headings = [
    "SL No",
    "Description of Material",
    "Material Code",
    "Unit",
    "Cumulative Issued",
    "Cumulative Consumed",
    "Difference",
  ];
  const headings2 = [
    "Reservation No",
    "Date of issue",
    "Material",
    "Material Code",
    "Quantity",
    "Unit",
  ];

  const modalClose = () => {
    setShow(false);
  };
  React.useEffect(() => {
    setLoading(true);
    setShow(true);
    let tempOpArray = [];
    requests
      .get({ url: "/projects/getProjectList" })
      .then((result) => {
        let areaList = [];
        areaAndZone.map((area) => {
          areaList.push(area.area._id);
        });
        let data = result.filter((project) =>
          designation == "Engineer" ||
          designation == "GM/DGM"
            ? areaList.includes(project.area._id)
            : true
        );
        tempOpArray = [
          {
            _id: "Select Project ID",
            name: "Select Project ID",
          },
        ];
        data.forEach((project) => {
          let tempOp = {};
          tempOp["_id"] = project["_id"];
          tempOp["name"] = project["projectId"];
          tempOpArray = [...tempOpArray, tempOp];
        });
        setOptions(tempOpArray);
        modalClose();
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  const formattedDate = (dateObj) => {
    const date = new Date(dateObj);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }

    return dt + "/" + month + "/" + year;
  };
  const submitHandler = () => {
    setLoading(true);
    setShow(true);
    requests
      .get({ url: "/projects/projectDetails?projectId=" + projectId })
      .then((result) => {
        let contents = [];
        let i = 1;
        setId(result.projectId);
        setVendor(result.vendor.name);
        result.materials.forEach((element) => {
          let material = element.material;
          let tempObj = {};
          tempObj["SL No"] = i;
          i = i + 1;
          tempObj["Description of Material"] = material.description;
          tempObj["Material Code"] = material.code;
          tempObj["Unit"] = material.unit;
          tempObj["Cumulative Issued"] = 0;
          tempObj["Cumulative Consumed"] = 0;
          contents = [...contents, tempObj];
          if (result.detailMaterials) {
            result.detailMaterials.forEach((p) => {
              contents.forEach((content) => {
                if (
                  p.material.description === content["Description of Material"]
                ) {
                  content["Cumulative Issued"] =
                    content["Cumulative Issued"] + p.quantity;
                }
              });
            });
          }
          if (result.progress) {
            result.progress.perDayProgress.forEach((p) => {
              p.materialUsed.forEach((material) => {
                contents.forEach((content) => {
                  if (material.name === content["Description of Material"]) {
                    content["Cumulative Consumed"] =
                      content["Cumulative Consumed"] + material.consumed;
                  }
                });
                contents.forEach((content) => {
                  if (
                    content["Cumulative Issued"] >=
                    content["Cumulative Consumed"]
                  ) {
                    content["Difference"] =
                      content["Cumulative Issued"] -
                      content["Cumulative Consumed"];
                  }
                });
              });
            });
          }
        });
        let tempMats = [];
        result.detailMaterials.map((p) => {
          let tempMat = {};
          tempMat["Reservation No"] = p["reservationNo"];
          tempMat["Date of issue"] = formattedDate(p["date"]);
          tempMat["Material"] = p["material"]["description"];
          tempMat["Material Code"] = p["material"]["code"];
          tempMat["Quantity"] = p["quantity"];
          tempMat["Unit"] = p["material"]["unit"];
          tempMats = [...tempMats, tempMat];
        });
        setMaterials(tempMats);
        setTableContents(contents);
        modalClose();
        setLoading(false);
      })
      .catch((error) => {
        setError(true);
        setLoading(false);
      });
  };
  return (
    <>
      {loading || error ? (
        <Modal show={show} noHandler={modalClose}>
          {error ? <p>Something went wrong!</p> : <Loader></Loader>}
        </Modal>
      ) : null}
      <ContainerHeader
        formName="Cumulative Issued - Cumulative Consumed (Job)"
        connLinks={connLinks}
      />
      <Paper>
        <DropDown
          label="Project ID"
          placeholder="Enter Project ID"
          size="wide"
          name="project-id"
          options={options}
          onChange={(e) => setProjectId(e.target.value)}
        />
        <div style={{ marginBottom: "20px" }}>
          <Button
            label="View Detailed cumulative Material List"
            className="success"
            onClick={submitHandler}
          />
        </div>
        {tableContents && tableContents.length ? (
          <MaterialTables
            headings={headings}
            data={tableContents}
            title={`CONTROL SHEET: Material Consumption statement for Project ID: ${id} Vendor: ${vendor}`}
          />
        ) : (
          "No materials have been issued"
        )}
        {materials.length === 0 ? null : (
          <div style={{ marginTop: "1.5%" }}>
            <MaterialTables
              headings={headings2}
              data={materials}
              title={`Detailed material list for Project ID:${id} Vendor: ${vendor}`}
            ></MaterialTables>
          </div>
        )}
      </Paper>
    </>
  );
};

export default CumulativeMaterialJob;
