import React, { useState, useEffect } from "react";
import axios from "axios";
import ContainerHeader from "../../../components/containerHeader/ContainerHeader";
import Paper from "../../../components/whiteCard/whiteCard";
import MaterialTables from "../../../components/materialTable/MaterialTables";
import Modal from "../../../components/ui/modal/Modal";
import Loader from "../../../components/spinner/Spinner";
import url from "../../../constants/url";
import '../jobsJE/JobsJE.css';

const NonEstimateProjects = () => {
    const [tableContents, setTableContents] = React.useState([]);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [changed, setChanged] = useState("");
    const [error, setError] = useState(false);

    const headings = [
        "Project ID",
        "Project Type",
        "Area",
        "Description",
        "DOS",
        "DOC",
        "Notification Number",
        "PO Number",
        "PR Number",
        "Show Cause Notice",
        "Show Cause Date of Issue",
        "Status",
        "Jr. Engineer",
        "Technician",
        "Vendor",
        "Zone",
    ];

    useEffect(() => {
        setLoading(true);
        setShow(true);
        axios
          .get(url + "/projects/getNonEstimateProjectList")
          .then((response) => {
            let tempArr = [];
            response.data.map((item) => {
              if (item.jrEngineer.name === changed) {
                let obj = {
                  "Jr. Engineer": item.jrEngineer.name,
                  Area: item.area.areaName,
                  Description:
                    item.description.length > 100
                      ? item.description.slice(0, 100)
                      : item.description,
                  DOC: new Date(item.doc).toLocaleDateString("en-GB"),
                  DOS: new Date(item.dos).toLocaleDateString("en-GB"),
                  "Notification Number": item.notificationNo,
                  "PO Number": item.poNumber,
                  "PR Number": item.prNumber,
                  "Project ID": item.projectId,
                  "Project Type": item.projectType,
                  "Show Cause Notice": item.showcause ? "Issued" : "Not Issued",
                  "Show Cause Date of Issue": item.showcause ? new Date(item.showcauseDate).toLocaleDateString("en-GB"): "-",
                  "Status": item.state,
                  Technician: item.technician.name,
                  Vendor: item.vendor.name,
                  Zone: item.zone.zoneName,
                };
                tempArr = [...tempArr, obj];
              }
            });
            setTableContents(tempArr);
            modalClose();
            setLoading(false);
          })
          .catch((e) => {
            setError(true);
            setLoading(false);
          });
    }, [changed]);

    const modalClose = () => {
        setShow(false);
    };

    return(
        <div className="vendorList">
        {loading || error ? (
            <Modal show={show} noHandler={modalClose}>
            {error ? <p>Something went wrong!</p> : <Loader></Loader>}
            </Modal>
        ) : null}
        <ContainerHeader formName="Non Estimate Projects"></ContainerHeader>
        <Paper>
            <MaterialTables 
                title="Non Estimate Projects"
                data={tableContents}
                headings={headings}
            />
        </Paper>    
        </div>
    )
}

export default NonEstimateProjects;