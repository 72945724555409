import React, { useState, useEffect } from "react";
import MaterialTable from "material-table";
import useWindowSize from "../windowSize/windowSize";
const MaterialTables = ({ headings, data, title, ispdf,rowClick }) => {
  const size = useWindowSize();

  const [selectedRow, setSelectedRow] = useState(null);
  const columns = [];
  const excludeHeads = [
    "Actions",
    "Uploaded Details",
    "View Show Cause Notice",
    "Drawing",
    "Photo",
    "Signature",
    "Estimate",
    "estimate",
    "drawing",
    "Upload Estimate",
    "Upload Drawing",
  ];

  headings.map((header) => {
    if (excludeHeads.includes(header)) {
      columns.push({
        title: header,
        field: header,
        export: false,
      });
    } else {
      columns.push({
        title: header,
        field: header,
      });
    }
  });

  if (size.width < 800) {
    title = "";
  }

  return (
    <MaterialTable
      title={title}
      columns={columns}
      data={data}
      onRowClick={rowClick ? rowClick : (evt, selectedRow) =>
        setSelectedRow(selectedRow.tableData.id)
      }
      options={{
        headerStyle: {
          backgroundColor: "#dcd6f7",
          color: "#3e206d",
        },

        rowStyle: (rowData) => ({
          backgroundColor:
            selectedRow === rowData.tableData.id ? "#EEE" : "#FFF",
        }),
        pageSize: 5,
        pageSizeOptions: [5,10,25,50],
        emptyRowsWhenPaging: false,
        exportButton:
          window.innerWidth < 800
            ? false
            : { csv: true, pdf: ispdf ? true : false },
        exportAllData: true,
      }}
    />
  );
};

MaterialTables.defaultProps = {
  ispdf: true,
};

export default MaterialTables;
