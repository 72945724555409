import React, { useEffect, useState } from "react";
import "./login.css";
import logo from "../../images/logo-full.png";
import * as requests from "../../api/requests";
import { Redirect } from "react-router-dom";
import Modal from "../../components/ui/modal/Modal";
import Loader from "../../components/spinner/Spinner";
import {url_upload} from "../../constants/url";
import play from "../../assets/icons/play.svg";
import axios from 'axios';

const Login = (props) => {
  const [user, setUser] = React.useState({ email: "", password: "" });
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [bg, setBg] = useState(false);
  const [showPlayer, setShowPlayer] = useState(false);

  useEffect(() => {
    console.log(url_upload)
    axios
      .get(`${url_upload}/loginbg.jpg`)
      .then((data) => {
        setBg(true);
      })
      .catch((e) => {
        setBg(false);
      });
  }, []);
  const login = () => {
    setError(false);
    setLoading(true);
    setShow(true);
    requests
      .post({
        url: "/users/login",
        body: { email: user.email, password: user.password },
      })
      .then((res) => {
        // localStorage.setItem('authCode', res.secret);
        console.log(res)
        localStorage.setItem("designation", res.designation);
        localStorage.setItem("_id", res._id);
        localStorage.setItem("name", res.name);
        localStorage.setItem("areaAndZone", JSON.stringify(res.areaAndZone));
        localStorage.setItem("email", res.email);
        localStorage.setItem("userCode", res.userCode);
        localStorage.setItem("status", res.status);
        localStorage.setItem("mobile", res.mobileNo);
        localStorage.setItem("photo", res.photo);
        // props.setLoggedIn(true);
        window.location.pathname = "/dashboard";
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        // console.log(e)
        setError(true);
        setLoading(false);
      });
  };

  const modalClose = () => {
    setShow(false);
  };

  const emailChange = (e) => {
    const temp = e.target.value;
    setUser({ ...user, email: temp });
  };
  const passChange = (e) => {
    const temp = e.target.value;
    setUser({ ...user, password: temp });
  };

  const passwordKeyPressHandler = (event) => {
    if (event.key === "Enter") {
      login();
    }
  };

  return (
    <div
      className="login-container"
      style={{
        backgroundImage: bg
          ? `url(${url_upload}/loginbg.jpg)`
          : `url(${url_upload}/defaultbg.jpg)`,
      }}
    >
      {loading || error ? (
        <Modal show={show} noHandler={modalClose}>
          {error ? <p>Something went wrong!</p> : <Loader></Loader>}
        </Modal>
      ) : null}
      {/* {props.loggedIn ? (window.location.pathname = "/dashboard") : null} */}
      {showPlayer ? (
        <div
          style={{
            position: "absolute",
            width: window.innerWidth,
            height: window.innerHeight,
            top: "50%",
            left: "50%",
            transform: "translate(-50%,-50%)",
          }}
        >
          <button
            onClick={() => setShowPlayer(false)}
            style={{ position: "absolute", top: "0", left: "0", zIndex: 100 }}
          >
            Close
          </button>
          <iframe
            src="https://drive.google.com/file/d/1CbSrLx0o85XNdQEovKt4a2k9oHTlvtVg/preview"
            height="100%"
            width="100%"
            style={{ position: "absolute", top: "0", left: "0", zIndex: 99 }}
          ></iframe>
        </div>
      ) : null}
      <div>
        <div className="project-title">
          {/* <h1>Oil Civil Project Planner</h1> */}
          <h1>Oil Civil Township Maintenance & Projects</h1>
        </div>
        <center>
          <div className="login-card">
            <img className="login-logo" src={logo} alt="logo" />
            <div className="login-input-container">
              <span>Email or User code</span>
              <input
                className="login-ipt"
                placeholder="Enter Email or User code"
                type="text"
                name="salarycode"
                value={user.email}
                onChange={emailChange}
              />
              <span>Password</span>
              <input
                className="login-ipt"
                placeholder="Password"
                type="password"
                name="password"
                value={user.password}
                onChange={passChange}
                onKeyPress={passwordKeyPressHandler}
              />
              <button className="login-signin" onClick={login}>
                SIGN IN
              </button>
              <p
                onClick={() => setShowPlayer(true)}
                style={{ cursor: "pointer" }}
              >
                <img
                  src={play}
                  alt="play"
                  height="16px"
                  style={{ position: "relative", top: "3px" }}
                />
                {"  "}View Tutorial
              </p>
            </div>
          </div>
        </center>
      </div>
    </div>
  );
};

export default Login;
