import "../jobsJE/JobsJE.css";
import React, { useState, useEffect } from "react";
import axios from "axios";
import ContainerHeader from "../../../components/containerHeader/ContainerHeader";
import Paper from "../../../components/whiteCard/whiteCard";
import MaterialTables from "../../../components/materialTable/MaterialTables";
import Modal from "../../../components/ui/modal/Modal";
import Loader from "../../../components/spinner/Spinner";
import DropDown from "../../../components/inputComponents/DropDown";
import url from "../../../constants/url";

const JobsEng = () => {
    const [tableContents, setTableContents] = React.useState([]);
    const [show, setShow] = useState(false);
    const [loading, setLoading] = useState(false);
    const [changed, setChanged] = useState("");
    const [error, setError] = useState(false);
    const [tableTitle, setTableTitle] = useState('')
    const connLinks = [
      {
        name: "All Reports",
        path: '/reports'
      }
    ]
    const headings = [
      "Engineer",
      "Area",
      "Description",
      "DOS",
      "DOC",
      "Notification Number",
      "PO Number",
      "PR Number",
      "Project ID",
      "Project Type",
      "Show Cause Notice",
      "Show Cause Date of Issue",
      "Status",
      "Subcontractor Name",
      "Subcontractor Number",
      "Jr. Engineer",
      "Technician",
      "Validity LCC",
      "Vendor",
      "Zone",
    ];
    const [areaOp, setAreaOp] = useState([
      {
        _id: null,
        name: "Select",
      },
    ]);
  
    useEffect(() => {
      setLoading(true);
      setShow(true);
      let jeArray = [
        {
          _id: null,
          name: "Select",
        },
      ];
      axios
        .get(url + "/users/getAllUsers")
        .then((res) => {
          res.data.map((item) => {
            if (item.designation === "Engineer")
              jeArray.push({
                _id: item.name,
                name: item.name,
              });
          });
          setAreaOp(jeArray);
          setTableContents(null);
          modalClose();
          setLoading(false);
        })
        .catch((e) => {
          setError(true);
          setLoading(false);
        });
    }, []);
  
    function handleChange(e) {
      setChanged(e.target.value);
      areaOp.forEach(je => {
        if(je._id === e.target.value)
          setTableTitle(je.name)
      });
    }
  
    useEffect(() => {
      setLoading(true);
      setShow(true);
      axios
        .get(url + "/projects/getProjectList")
        .then((response) => {
          let tempArr = [];
          response.data.map((item) => {
            if (item.engineer.name === changed) {
              let obj = {
                "Engineer": item.engineer.name,
                Area: item.area.areaName,
                Description:
                  item.description.length > 100
                    ? item.description.slice(0, 100)
                    : item.description,
                DOC: new Date(item.doc).toLocaleDateString("en-GB"),
                DOS: new Date(item.dos).toLocaleDateString("en-GB"),
                "Notification Number": item.notificationNo,
                "PO Number": item.poNumber,
                "PR Number": item.prNumber,
                "Project ID": item.projectId,
                "Project Type": item.projectType,
                "Show Cause Notice": item.showcause ? "Issued" : "Not Issued",
                "Show Cause Date of Issue": item.showcause ? new Date(item.showcauseDate).toLocaleDateString("en-GB"): "-",
                "Status": item.state,
                "Subcontractor Name": item.subcontractorName
                  ? item.subcontractorName
                  : "-",
                "Subcontractor Number": item.subcontractorNumber
                  ? item.subcontractorNumber
                  : "-",
                "Jr. Engineer": item.jrEngineer.name,
                Technician: item.technician.name,
                "Validity LCC": new Date(item.validityLLC).toLocaleDateString(
                  "en-GB"
                ),
                Vendor: item.vendor.name,
                Zone: item.zone.zoneName,
              };
              tempArr = [...tempArr, obj];
            }
          });
          setTableContents(tempArr);
          modalClose();
          setLoading(false);
        })
        .catch((e) => {
          setError(true);
          setLoading(false);
        });
    }, [changed]);
  
    const modalClose = () => {
      setShow(false);
    };
  
    return (
      <div className="vendorList">
        {loading || error ? (
          <Modal show={show} noHandler={modalClose}>
            {error ? <p>Something went wrong!</p> : <Loader></Loader>}
          </Modal>
        ) : null}
        <ContainerHeader formName="Engineer Wise Jobs" connLinks={connLinks}></ContainerHeader>
        <Paper>
          <div className="Engselect">
            <DropDown
              label="Engineer*"
              name="area"
              options={areaOp}
              size="wide"
              onChange={handleChange}
              // value={formData.area}
            />
          </div>
          {tableContents && tableContents.length > 0 ? (
            <MaterialTables
              title={`${tableTitle} Engineer Wise Jobs List`}
              data={tableContents}
              headings={headings}
              ispdf={false}
            />
          ) : (
            "Please Select an Engineer"
          )}
        </Paper>
      </div>
    );
}

export default JobsEng;