import React, { useState, useEffect } from "react";
import "./EditNotification.css";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Whitecard from "../../components/whiteCard/whiteCard";
import Input from "../../components/inputComponents/Input";
import DropDown from "../../components/inputComponents/DropDown";
import Button from "../../components/inputComponents/Button";
import TextArea from "../../components/inputComponents/TextArea";
import Axios from "axios";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import url from "../../constants/url";
import autCheck from "../../helpers/auth.helpers";

const NotificationListing = (props) => {

  // const { email } = autCheck(localStorage.getItem('authCode'));
  const { email } = localStorage;

  const [details, setDetails] = useState({
    projectType: "",
    projectId: "",
    notificationNumber: 0,
    area: "",
    areaName: "",
    installation: "",
    nameRequisitioner: "",
    departmentRequisitioner: "",
    phNumberRequisitioner: 0,
    description: "",
    drawing: "",
  });
  const [area, setArea] = useState([]);
  const [installations, setInstalations] = useState({});
  const [flag, setFlag] = useState(false);
  const [show, setShow] = useState(false);
  const [functionToPass, setFunctionToPass] = useState(null);
  const [ques, setQues] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [file, setFile] = useState();
  const connLinks = [
    {
      name: "Notifications List",
      path: '/notifications'
    }
  ]
  useEffect(() => {
    setLoading(true);
    setShow(true);
    Axios.get(url + "/notifications/" + props.match.params.id)
      .then((response) => {
        let tempObj = { ...details };
        let apiObj = {};
        apiObj = response.data[0];
        tempObj["projectType"] = apiObj["projectType"];
        tempObj["areaName"] = apiObj["area"]["areaName"];
        tempObj["area"] = apiObj["area"]["_id"];
        tempObj["installation"] = apiObj["installation"]["_id"];
        tempObj["installationName"] = apiObj["installation"]["name"];
        tempObj["notificationNumber"] = apiObj["notificationNumber"];
        tempObj["projectId"] = apiObj["projectId"];
        tempObj["drawing"] = "noimage.jpg";
        tempObj["description"] = apiObj["description"];
        tempObj["departmentRequisitioner"] = apiObj["departmentRequisitioner"];
        tempObj["nameRequisitioner"] = apiObj["nameRequisitioner"];
        tempObj["phNumberRequisitioner"] = apiObj["phNumberRequisitioner"];
        setDetails(tempObj);
        Axios.post(url + "/users/getUser", {
          email: email,
        })
          .then((user) => {
            let tempArea = [];
            user.data[0]["areaAndZone"].map((anz) => {
              if (anz["area"]["_id"] !== tempObj["area"]) {
                tempArea.push({
                  name: anz["area"]["areaName"],
                  _id: anz["area"]["_id"],
                });
              }
            });
            tempArea.unshift({
              name: tempObj["areaName"],
              _id: tempObj["area"],
            });
            setArea(tempArea);
            Axios.get(url + "/installations/allInstallations")
              .then((response) => {
                let inst = [];
                response.data.map((z) => {
                  let tempObj = {};
                  if (z._id != apiObj["installation"]["_id"]) {
                    tempObj["name"] = z.name;
                    tempObj["_id"] = z._id;
                    inst.push(tempObj);
                  }
                });
                inst.unshift({
                  name: apiObj["installation"]["name"],
                  _id: apiObj["installation"]["_id"],
                });
                setInstalations(inst);
                setFlag(true);
                modalClose();
                setLoading(false);
              })
              .catch((err) => {
                setError(true);
                setLoading(false);
              });
          })
          .catch((err) => {
            setError(true);
            setLoading(false);
          });
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }, []);
  const projectType = [
    { name: "Select", _id: "0" },
    { name: "Miscellaneous", _id: "Miscellaneous" },
    { name: "Others", _id: "Others" },
    { name: "Contract", _id: "Contract" },
  ];
  const Install = [
    { name: "Select", _id: "0" },
    { name: "Agra", _id: "Agra" },
    { name: "Noida", _id: "Noida" },
  ];

  const modalVisible = () => {
    setError(false);
    setFunctionToPass(() => () => handleSubmit());
    setQues("Do you want to edit this notification?");
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };

  const inputChangeHandler = (event, val) => {
    setDetails({
      ...details,
      [val]: event.target.value,
    });
  };

  const handleSubmit = () => {
    setLoading(true);
    const data = new FormData();
    data.append("area", details.area);
    data.append("installation", details.installation);
    data.append("projectType", details.projectType);
    data.append("notificationNumber", details.notificationNumber);
    data.append("description", details.description);
    data.append("nameRequisitioner", details.nameRequisitioner);
    data.append("departmentRequisitioner", details.departmentRequisitioner);
    data.append("phNumberRequisitioner", details.phNumberRequisitioner);
    if (file) {
      data.append("file", file);
    }
    Axios.patch(url + "/notifications/" + props.match.params.id, data)
      .then((response) => {
        setLoading(false);
        modalClose();
        props.history.push("/notifications");
      })
      .catch((err) => {
        setLoading(false);
        setError(true);
      });
  };
  return (
    <div>
      <Modal show={show} noHandler={modalClose}>
        {!loading ? (
          !error ? (
            <ConfirmContent
              yesHandler={functionToPass}
              noHandler={modalClose}
              question={ques}
            ></ConfirmContent>
          ) : (
            <p>Something went wrong!</p>
          )
        ) : (
          <Loader></Loader>
        )}
      </Modal>
      <ContainerHeader formName="Edit Notification" connLinks={connLinks}></ContainerHeader>
      {flag ? (
        <Whitecard>
          <div id="an-col">
            <div className="an-row">
              <div className="an-row-in">
                {" "}
                <DropDown
                  options={projectType}
                  label="Project Type"
                  size="wide"
                  value={details.projectType}
                  onChange={(e) => inputChangeHandler(e, "projectType")}
                />
              </div>

              <div className="an-row-in">
                {" "}
                <Input
                  name="notification-no"
                  label="Notification No."
                  placeholder="Enter No."
                  size="wide"
                  value={details.notificationNumber}
                  onChange={(e) => inputChangeHandler(e, "notificationNumber")}
                />
              </div>
            </div>
            <div className="an-row">
              <div className="an-row-in">
                {" "}
                <Input
                  name="upload notification"
                  type="file"
                  label="Upload Notification"
                  placeholder="Enter name"
                  size="wide"
                  onChange={(event) => {
                    const file = event.target.files[0];
                    setFile(file);
                  }}
                />
              </div>
              <div className="an-row-in">
                {" "}
                <DropDown
                  options={area}
                  label="Area"
                  size="wide"
                  onChange={(e) => inputChangeHandler(e, "area")}
                />
              </div>
              <div className="an-row-in">
                {" "}
                <DropDown
                  options={installations}
                  label="Installation"
                  size="wide"
                  onChange={(e) => inputChangeHandler(e, "installation")}
                />
              </div>
            </div>
            <div className="an-row">
              <div className="an-row-in">
                {" "}
                <Input
                  name="req-name"
                  label="Name of Requisitioner"
                  placeholder="Enter Name"
                  size="wide"
                  value={details.nameRequisitioner}
                  onChange={(e) => inputChangeHandler(e, "nameRequisitioner")}
                />
              </div>
              <div className="an-row-in">
                {" "}
                <Input
                  name="req-department"
                  label="Requisitioner Department"
                  placeholder="Enter Department"
                  size="wide"
                  value={details.departmentRequisitioner}
                  onChange={(e) =>
                    inputChangeHandler(e, "departmentRequisitioner")
                  }
                />
              </div>
              <div className="an-row-in">
                {" "}
                <Input
                  name="req-no"
                  label="Phone No. of Requisitioner"
                  placeholder="Enter Number"
                  size="wide"
                  value={details.phNumberRequisitioner}
                  onChange={(e) =>
                    inputChangeHandler(e, "phNumberRequisitioner")
                  }
                />
              </div>
            </div>
            <div className="an-row">
              <div className="an-row-in">
                <TextArea
                  rows="30"
                  cols="10"
                  label="Project Description"
                  value={details.description}
                  size="wide"
                  onChange={(e) => inputChangeHandler(e, "description")}
                />
              </div>
            </div>
            <div className="an-row-2">
              <Button
                className="success"
                label="Update"
                onClick={modalVisible}
              />
            </div>
          </div>
        </Whitecard>
      ) : null}
    </div>
  );
};

export default NotificationListing;
