import React, { useState, useEffect } from "react";
import "./AddMaterial.css";
import Input from "../../components/inputComponents/Input";
import Paper from "../../components/whiteCard/whiteCard";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Button from "../../components/inputComponents/Button";
import EditDelete from "../../components/editDelete/EditDelete";
import Axios from "axios";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import MaterialTables from "../../components/materialTable/MaterialTables";
import url, { url_upload } from "../../constants/url";
import Alert from "@material-ui/lab/Alert";
import Typography from "@material-ui/core/Typography";

const AddMaterial = () => {
  const [tableContents, setTableContents] = useState([]);
  const [show, setShow] = useState(false);
  const [functionToPass, setFunctionToPass] = useState(null);
  const [ques, setQues] = useState("");
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);

  var [isSubmit, setSubmit] = useState(false);
  const [formErrors,setFormErrors] = useState([])
  const [error, setError] = useState(false);
  const [file, setFile] = useState(null);
  const [upload, setUpload] = useState(false);

  const [formData, setFormData] = useState({
    description: "",
    details: "No Details",
    id: "",
    code: "",
    unit: "",
  });

  const headings = [
    "SL No",
    "Description of Material",
    "Material Code",
    "Unit",
    "Uploaded Details",
    "Actions",
  ];
  const [edit, setEdit] = useState(false);
  useEffect(() => {
    setLoading(true);
    setShow(true);
    Axios.get(url+"/material/getMaterialList")
      .then((response) => {
        let apiData = [];
        let contents = [];
        apiData = response.data;
        let i = 1;
        apiData.map((data) => {
          let tempObj = {};
          tempObj["SL No"] = i;
          i = i + 1;
          tempObj["Description of Material"] = data["description"];
          tempObj["Material Code"] = data["code"];
          tempObj["Unit"] = data["unit"];
          tempObj["Uploaded Details"] =
            data["details"] === "no-details" ? (
              data["details"]
            ) : (
              <a href={`${url_upload}/material/${data["details"]}`} target="_blank">
                View Details
              </a>
            );
          tempObj["Actions"] = (
            <EditDelete
              deleteHandler={() => {
                modalVisibleForDelete(data["_id"]);
              }}
              editHandler={() => {
                editMaterial(data);
              }}
            ></EditDelete>
          );
          contents = [...contents, tempObj];
        });
        setTableContents(contents);
        modalClose();
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }, [update]);

  const modalVisibleForEdit = () => {
    setError(false);
    let errors = []
    let toCheck = {}
    toCheck = {
      "description":"DESCRIPTION OF THE MATERIAL",
      "code":"MATERIAL CODE",
      "unit":"UNIT OF MEASUREMENT"
    }
    Object.keys(toCheck).map(key=>{
      if(!formData[key]){
        errors.push(toCheck[key]+" is required")
      }
    });
    if (errors.length > 0) {
      setFormErrors(errors);
      return;
    }
    setFormErrors([]);
    setFunctionToPass(edit ? () => () => editForm() : () => () => submitForm());
    setQues(
      edit
        ? "Do you want to edit this material?"
        : "Do you want to submit this material?"
    );
    setShow(true);
  };

  const modalVisibleForDelete = (id) => {
    setError(false);
    setFunctionToPass(() => () => deleteMaterial(id));
    setQues("Do you want to delete this material?");
    setShow(true);
  };

  const onBulkUpload = () => {

    const data = new FormData();
    data.append("name", "bulkUpload");
    data.append("file", file);
    modalClose();
    Axios.post(`${url}/bulkUpload/materialUpload`, data)
    .then((res) => {
      setUpdate(!update);
    })
    .catch((err) => {
      setShow(true);
      setLoading(false);
      setUpload(false);
      setError(true);
    })
  }

  const modalBulkUpload = () => {
    setUpload(true);
    setFormErrors([]);  
    setShow(true);
  }

  const modalClose = () => {
    setShow(false);
    setUpload(false);
  };

  //Delete a Material
  function deleteMaterial(id) {
    setLoading(true);
    Axios.delete(url + `/material/deleteMaterial/${id}`)
      .then((_) => {
        modalClose();
        setLoading(false);
        setFormData({
          description: "",
          details: "",
          id: "",
          code: "",
          unit: "",
        });
        setUpdate(!update);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }

  //Edit Material
  function editMaterial(data) {
    setEdit(true);
    setFormData({
      description: data["description"],
      details: data["details"],
      id: data["_id"],
      code: data["code"],
      unit: data["unit"],
    });
  }

  function handleChange(event) {
    const { name, value } = event.target;
    setFormData((prev) => {
      return {
        ...prev,
        [name]: value,
      };
    });
  }

  //Add Material
  function submitForm() {
    setLoading(true);
    const data = new FormData();
    data.append("description", formData.description);
    data.append("code", formData.code);
    data.append("unit", formData.unit);
    data.append("file", formData.details);
    Axios.post(url + "/material/createMaterial", data)
      .then((result) => {
        modalClose();
        setLoading(false);
        setFormData({
          description: "",
          details: "",
          id: "",
          code: "",
          unit: "",
        });
        setSubmit(true);
        setUpdate(!update);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }
  //Edit Material
  function editForm() {
    setLoading(true);
    const data = new FormData();
    data.append("description", formData.description);
    data.append("code", formData.code);
    data.append("unit", formData.unit);
    if (formData.details) {
      data.append("file", formData.details);
    }
    Axios.patch(url + `/material/editMaterial/${formData.id}`, data)
      .then((result) => {
         setEdit(false)
        modalClose();
        setLoading(false);
        setEdit(false)
        setFormData({
          description: "",
          details: "",
          id: "",
          code: "",
          unit: "",
        });
        setSubmit(true);
        setUpdate(!update);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }

  return (
    <div id="add-material">
      <Modal show={show} noHandler={modalClose}>
      {!loading ? 
          !upload ? (
          !error ? (
            <ConfirmContent
              yesHandler={functionToPass}
              noHandler={modalClose}
              question={ques}
            ></ConfirmContent>
          ) : (
            <p>Something went wrong!</p>
          )
        )
        :
        <>
          <Typography>Select a .xlsx file of not more than 2MB that contains the vendor details.</Typography>
            <Input
                type="file"
                value={isSubmit ? "" : null }
                onChange={(e) => {
                    setSubmit(false);
                    setFile(e.target.files[0])}
                }
                style={{marginBottom: '10px'}}
            />
            <Button 
                className="primary"
                type="button"
                label="Bulk Upload"
                onClick={onBulkUpload}
                disabled={file ? false : true}
            />
            <Button 
                className="primary"
                type="button"
                label="Cancel"
                onClick={() => {
                    setFile(false);
                    modalClose();
                }}
            />
        </>
        : (
          <Loader></Loader>
        )}
      </Modal>
      <ContainerHeader formName="Add Material"> </ContainerHeader>
      <Paper>
        <div className="add-material-form">
          <div className="fields">
            <div className="field">
              <Input
                name="description"
                label="Description of the Material *"
                size="wide"
                onChange={handleChange}
                value={formData.description}
              />
            </div>
            <div className="field">
              <Input
                name="code"
                label="Material Code *"
                size="wide"
                onChange={handleChange}
                value={formData.code}
              />
            </div>
            <div className="field">
              <Input
                name="unit"
                label="Unit of Measurement *"
                size="wide"
                onChange={handleChange}
                value={formData.unit}
              />
            </div>
            <div className="field">
              <Input
                type="file"
                label="Upload Details"
                name="details"
                size="wide"
                onChange={(event) => {
                  setSubmit(false);
                  const file = event.target.files[0];
                  setFormData((prev) => {
                    return {
                      ...prev,
                      details: file,
                    };
                  });
                }}
                value={isSubmit ? "" : null}
              />
            </div>
          </div>
          {formErrors.map((error) => {
            return <Alert severity="info">{error}</Alert>;
          })}
          <br />
          <hr />
          {edit ? (
            <Button
              className="primary"
              type="submit"
              label="Edit"
              onClick={modalVisibleForEdit}
            />
          ) : (
            <Button
              className="success"
              type="submit"
              label="Submit"
              onClick={modalVisibleForEdit}
            />
          )}
            {/* <Button 
              className="primary"
              label="Bulk Upload"
              onClick={modalBulkUpload}
          /> */}
        </div>
        <br />
        <br />
        <MaterialTables
          headings={headings}
          data={tableContents}
          title="Material Database"
        />
      </Paper>
    </div>
  );
};

export default AddMaterial;
