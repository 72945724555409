import React, { useState, useEffect } from "react";
import "./Exit.css";
import Input from "../../../components/inputComponents/Input";
import DropDown from "../../../components/inputComponents/DropDown";
import Button from "../../../components/inputComponents/Button";
import MaterialTables from "../../../components/materialTable/MaterialTables";
import Axios from "axios";
import Modal from "../../../components/ui/modal/Modal";
import ConfirmContent from "../../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../../components/spinner/Spinner";
import EditDelete from "../../../components/editDelete/EditDelete";
import url from "../../../constants/url";
import Switch from "@material-ui/core/Switch";
import Alert from "@material-ui/lab/Alert";
import TextArea from "../../../components/inputComponents/TextArea";
import Typography from "@material-ui/core/Typography";

const headings = [
  "Date",
  "Reservation Number",
  "Gate Pass Number",
  "Delivery Note Number",
  "Material/Machinery",
  "Quantity",
  "Unit",
  "vendor Code",
  "Vendor Name",
  "Remark",
  "Project Id",
  "Action",
];

const authCheck = require("../../../helpers/auth.helpers");

const Exit = (props) => {

  // const { designation } = authCheck(localStorage.getItem('authCode'));
  const { designation } = localStorage;
  const [upload, setUpload] = useState(false);
  const [file, setFile] = useState(null);

  const [tableContent, setTableContent] = useState([]);
  const [materials, setMaterials] = useState([]);
  const [machineries, setMachineries] = useState([]);
  const [vendors, setVendors] = useState([]);
  const [ques, setQues] = useState("");
  const [loading, setLoading] = useState(false);
  const [update, setUpdate] = useState(false);
  const [show, setShow] = useState(false);
  var [isSubmit, setSubmit] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [error, setError] = useState(false);
  const [edit, setEdit] = useState(false);
  const [isMachinery, setIsMachinery] = useState(false);
  const [showForm, setShowForm] = useState(true);
  const [formData, setFormData] = useState({
    Date: null,
    "Reservation Number": null,
    "Gate Pass Number": null,
    "Delivery Note Number": null,
    Material: null,
    Quantity: null,
    Unit: null,
    "vendor Code": null,
    "Vendor Name": null,
    Remark: null,
    "Project Id": null,
    Action: null,
  });
  const [godown, setGodown] = useState(props.godown);
  const [functionToPass, setFunctionToPass] = useState(null);

  React.useEffect(() => {
    Axios.get(url + "/godowns/getGowdown")
      .then((data) => {
        let temp = [];
        data.data
          .filter((godown) => {
            return props.godown._id == godown._id;
          })[0]
          .register.map((entry) => {
            if (entry.quantityOut) {
              temp = [
                ...temp,
                {
                  Date: formattedDate2(entry.date),
                  "Reservation Number": entry.reservationNumber,
                  "Gate Pass Number": entry.gatePassNumber,
                  "Delivery Note Number": entry.deliveryNoteNumber,
                  "Material/Machinery": entry.material
                    ? entry.material.description
                    : entry.machinery.description,
                  Quantity: entry.quantityOut,
                  Unit: entry.unit,
                  "vendor Code": entry.vendor.code,
                  "Vendor Name": entry.vendor.name,
                  Remark: entry.remark,
                  "Project Id": entry.job,
                  Action: (
                    <>
                      {designation === "SAP" ||
                      (new Date() - new Date(entry.date).getTime()) /
                        (1000 * 3600 * 24) <
                        5 ? (
                        <EditDelete
                          deleteHandler={() => {
                            modalVisibleForDelete(entry["_id"]);
                          }}
                          editHandler={() => {
                            editEntry(entry);
                          }}
                        ></EditDelete>
                      ) : (
                        "YOU CAN'T EDIT THIS ENTRY NOW"
                      )}
                    </>
                  ),
                },
              ];
            }
          });
        setTableContent(temp);
      })
      .catch((e) => {
        console.log(e);
      });
  }, [update]);
  const sortByKey = (array, key) => {
    return array.sort(function (a, b) {
      var x = a[key].toUpperCase();
      var y = b[key].toUpperCase();
      return x < y ? -1 : x > y ? 1 : 0;
    });
  };
  function deleteEntry(id) {
    Axios.post(url + "/register/deleteRegister", {
      godownId: godown._id,
      registerId: id,
    })
      .then((data) => {
        setShow(false);
        setUpdate(!update);
      })
      .catch((e) => {
        console.log(e);
      });
  }

  const modalVisibleForDelete = (id) => {
    setError(false);
    setFunctionToPass(() => () => deleteEntry(id));
    setQues("Do you want to delete this Entry?");
    setShow(true);
  };

  const modalVisibleForEdit = () => {
    let errors = [];
    if (!formData.Material && !formData.Machinery)
      errors.push("Select Material");
    if (
      formData["Reservation Number"] &&
      formData["Gate Pass Number"] &&
      formData["Delivery Note Number"]
    )
      errors.push(
        "Enter Reservation Number OR Gate Pass Number OR Delivery Note Number"
      );
    if (errors.length > 0) {
      setFormErrors(errors);
      return;
    }
    setError(false);
    setFunctionToPass(() => () => updateEntry());
    setQues("Do you want to edit this Entry?");
    setShow(true);
  };

  const modalVisibleForSubmit = () => {
    let errors = [];
    if (!formData.Material && !formData.Machinery)
      errors.push("Select Material");
    if (
      formData["Reservation Number"] &&
      formData["Gate Pass Number"] &&
      formData["Delivery Note Number"]
    )
      errors.push(
        "Enter Reservation Number OR Gate Pass Number OR Delivery Note Number"
      );
    if (errors.length > 0) {
      setFormErrors(errors);
      return;
    }
    setError(false);
    setFunctionToPass(() => () => addToSubmitList());
    setQues("Do you want to submit this Entry?");
    setShow(true);
  };

  function editEntry(Data) {
    setEdit(true);
    setFormData({
      Date: Data.date,
      "Reservation Number": Data.reservationNumber,
      "Gate Pass Number": Data.gatePassNumber,
      "Delivery Note Number": Data.deliveryNoteNumber,
      Material: Data.material
        ? Data.material.description
        : Data.machinery.description,
      Quantity: Data.quantityOut,
      Unit: Data.unit,
      "vendor Code": Data.vendor.code,
      "Vendor Name": Data.vendor.name,
      Remark: Data.remark,
      "Project Id": Data.job,
      _id: Data._id,
    });
  }

  useEffect(() => {
    let temp = [];
    godown.register.map((entry) => {
      if (entry.quantityOut) {
        temp = [
          ...temp,
          {
            Date: formattedDate2(entry.date),
            "Reservation Number": entry.reservationNumber,
            "Gate Pass Number": entry.gatePassNumber,
            "Delivery Note Number": entry.deliveryNoteNumber,
            "Material/Machinery": entry.material
              ? entry.material.description
              : entry.machinery.description,
            Quantity: entry.quantityOut,
            Unit: entry.unit,
            "vendor Code": entry.vendor.code,
            "Vendor Name": entry.vendor.name,
            Remark: entry.remark,
            "Project Id": entry.job,
            Action: (
              <>
                {designation === "SAP" ||
                (new Date() - new Date(entry.date).getTime()) /
                  (1000 * 3600 * 24) <
                  5 ? (
                  <EditDelete
                    deleteHandler={() => {
                      modalVisibleForDelete(entry["_id"]);
                    }}
                    editHandler={() => {
                      editEntry(entry);
                    }}
                  ></EditDelete>
                ) : (
                  "YOU CAN'T EDIT THIS ENTRY NOW"
                )}
              </>
            ),
          },
        ];
      }
    });
    setTableContent(temp);
    setLoading(true);
    setShow(true);
    Axios.get(url + "/material/getMaterialList")
      .then((response) => {
        let apiData = [];
        let contents = [];
        apiData = response.data;
        apiData.map((data) => {
          let tempObj = {};
          tempObj["_id"] = data["_id"];
          tempObj["name"] = data["description"];
          tempObj["id"] = data["description"];
          tempObj["Material Code"] = data["code"];
          tempObj["Unit"] = data["unit"];
          contents = [...contents, tempObj];
        });
        sortByKey(contents, "name");
        contents = [{ name: "Select", id: "Select" }, ...contents];
        setMaterials(contents);
        modalClose();
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
    Axios.get(url + "/machinery/getMachineryList")
      .then((response) => {
        let apiData = [];
        let contents = [];
        apiData = response.data;
        apiData.map((data) => {
          let tempObj = {};
          tempObj["_id"] = data["_id"];
          tempObj["name"] = data["description"];
          tempObj["id"] = data["description"];
          contents = [...contents, tempObj];
        });
        sortByKey(contents, "name");
        contents = [{ name: "Select", id: "Select" }, ...contents];
        setMachineries(contents);
        modalClose();
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
    let tempArr = [];
    Axios.get(url + "/vendors/getVendors")
      .then((response) => {
        response.data.map((vendor) => {
          let tempObj = {};
          tempObj["name"] = vendor["code"];
          tempObj["Name"] = vendor["name"];
          tempObj["id"] = vendor["code"];
          tempObj["Code"] = vendor["code"];
          tempObj["_id"] = vendor["_id"];
          tempArr = [...tempArr, tempObj];
        });
        tempArr.sort((a, b) => parseInt(a.Code) - parseInt(b.Code));
        tempArr = [{ name: "Select", id: "Select" }, ...tempArr];
        setVendors(tempArr);
        modalClose();
        setLoading(false);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  const addToSubmitList = () => {
    setLoading(true);
    Axios.post(url + "/register/addEntry", {
      godownId: godown._id,
      date: formData.Date,
      reservationNumber: formData["Reservation Number"],
      gatePassNumber: formData["Gate Pass Number"],
      deliveryNoteNumber: formData["Delivery Note Number"],
      quantityOut: parseInt(formData.Quantity),
      unit: formData.Unit ? formData.Unit : "Unit",
      job: formData["Project Id"],
      vendor: formData.vendorId,
      material: formData.materialId,
      machinery: formData.machineryId,
      remark: formData.Remark,
    })
      .then(async (data) => {
        await setFormData({
          "Reservation Number": "",
          "Gate Pass Number": "",
          "Delivery Note Number": "",
          Material: "",
          Machinery: "",
          Quantity: "",
          Unit: "",
          "vendor Code": "",
          "Vendor Name": "",
          Remark: "",
          "Project Id": "",
        });
        await setShowForm(false);
        await setShowForm(true);
        setUpdate(!update);
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
        setLoading(false);
      });
  };

  const updateEntry = () => {
    setLoading(true);
    Axios.patch(url + `/register/updateRegisters/${formData._id}`, {
      godownId: godown._id,
      date: formData.Date,
      reservationNumber: formData["Reservation Number"],
      gatePassNumber: formData["Gate Pass Number"],
      deliveryNoteNumber: formData["Delivery Note Number"],
      quantityOut: formData.Quantity,
      unit: formData.Unit,
      job: formData["Project Id"],
      vendor: formData.vendorId,
      material: formData.materialId,
      remark: formData.Remark,
    })
      .then(async (data) => {
        setUpdate(!update);
        await setFormData({
          "Reservation Number": "",
          "Gate Pass Number": "",
          Material: "",
          Quantity: "",
          Unit: "",
          "vendor Code": "",
          "Vendor Name": "",
          Remark: "",
          "Project Id": "",
        });
        await setShowForm(false);
        await setShowForm(true);
        setIsMachinery(false);
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
        setLoading(false);
      });
  };

  const onBulkUpload = () => {
    const data = new FormData();
    data.append("name", "bulkUpload");
    data.append("file", file);
    modalClose();
    Axios.post(`${url}/bulkUpload/registerUpload`, data)
    .then((res) => {
      setUpdate(!update);
    })
    .catch((err) => {
      setShow(true);
      setLoading(false);
      setUpload(false);
      setError(true);
    })
}

const modalBulkUpload = () => {
    setUpload(true);
    setFormErrors([]);  
    setShow(true);
}

const modalClose = () => {
    setShow(false);
    setUpload(false);
};
  const changeType = () => {
    setIsMachinery(!isMachinery);
  };
  const formattedDate = (dateObj) => {
    const date = new Date(dateObj);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    return year + "-" + month + "-" + dt;
  };
  const formattedDate2 = (dateObj) => {
    const date = new Date(dateObj);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    return dt + "-" + month + "-" + year;
  };
  return (
    <>
      <Modal show={show} noHandler={modalClose}>
        {!loading ? 
          !upload ? (
          !error ? (
            <ConfirmContent
              yesHandler={functionToPass}
              noHandler={modalClose}
              question={ques}
            ></ConfirmContent>
          ) : (
            <p>Something went wrong!</p>
          )
        )
        :
        <>
        <Typography>Select a .xlsx file of not more than 2MB that contains the exit register details.</Typography>
        <Input
            type="file"
            value={isSubmit ? "" : null }
            onChange={(e) => {
                setSubmit(false);
                setFile(e.target.files[0])}
            }
            style={{marginBottom: '10px'}}
        />
        {/* <Button 
            className="primary"
            type="button"
            label="Bulk Upload"
            onClick={onBulkUpload}
            disabled={file ? false : true}
        /> */}
        <Button 
            className="primary"
            type="button"
            label="Cancel"
            onClick={() => {
                setFile(false);
                modalClose();
            }}
          />
        </>
         : (
          <Loader></Loader>
        )}
      </Modal>
      <h1>Stock Exit Form</h1>
      {formErrors.map((error) => {
        return <Alert severity="info">{error}</Alert>;
      })}
      {showForm ? (
        <>
          {edit ? null : (
            <>
              <span>Do you want to add Machinery? </span>
              <Switch
                checked={isMachinery}
                onClick={changeType}
                color="primary"
                name="checkedB"
                inputProps={{ "aria-label": "primary checkbox" }}
              />
            </>
          )}
          <div className="ims-entry-form">
            <div>
              <Input
                label="Enter date"
                type="date"
                size="wide"
                onChange={(e) =>
                  setFormData({ ...formData, Date: e.target.value })
                }
                value={formData.Date ? formattedDate(formData.Date) : null}
              />
            </div>
            {formData["Gate Pass Number"] ||
            formData["Delivery Note Number"] ? null : (
              <div>
                <Input
                  label="Reservation No."
                  placeholder="Enter Reservation No."
                  size="wide"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      "Reservation Number": e.target.value,
                    })
                  }
                  value={formData["Reservation Number"]}
                />
              </div>
            )}
            {formData["Reservation Number"] ||
            formData["Delivery Note Number"] ? null : (
              <div>
                <Input
                  label="Gate Pass No."
                  placeholder="Enter gate pass No."
                  size="wide"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      "Gate Pass Number": e.target.value,
                    })
                  }
                  value={formData["Gate Pass Number"]}
                />
              </div>
            )}
            {formData["Reservation Number"] ||
            formData["Gate Pass Number"] ? null : (
              <div>
                <Input
                  label="Delivery Note No."
                  placeholder="Enter delivery note No."
                  size="wide"
                  onChange={(e) =>
                    setFormData({
                      ...formData,
                      "Delivery Note Number": e.target.value,
                    })
                  }
                  value={formData["Delivery Note Number"]}
                />
              </div>
            )}
            {edit ? (
              <div>
                <Input
                  label="Material/Machinery"
                  disabled="true"
                  size="wide"
                  value={
                    formData.Material ? formData.Material : formData.Machinery
                  }
                ></Input>
              </div>
            ) : isMachinery ? (
              <div>
                <DropDown
                  label="Select Machinery"
                  options={machineries}
                  size="wide"
                  onChange={(e) => {
                    setFormData({ ...formData, Machinery: e.target.value });
                    setFormData({
                      ...formData,
                      Machinery:
                        e.target.value === "Select"
                          ? null
                          : machineries.filter((machinery) => {
                              return e.target.value == machinery._id;
                            })[0].name,
                      machineryId:
                        e.target.value === "Select" ? null : e.target.value,
                    });
                  }}
                ></DropDown>
              </div>
            ) : (
              <div>
                <DropDown
                  label="Select Material"
                  options={materials}
                  size="wide"
                  onChange={(e) => {
                    setFormData({ ...formData, Material: e.target.value });
                    setFormData({
                      ...formData,
                      Material:
                        e.target.value === "Select"
                          ? null
                          : materials.filter((material) => {
                              return e.target.value == material._id;
                            })[0].name,
                      Unit:
                        e.target.value === "Select"
                          ? null
                          : materials.filter((material) => {
                              return e.target.value == material._id;
                            })[0].Unit,
                      materialId:
                        e.target.value === "Select" ? null : e.target.value,
                    });
                  }}
                ></DropDown>
              </div>
            )}
            <div>
              <Input
                label={`Quantity ${
                  isMachinery ? "" : formData.Unit ? formData.Unit : null
                }`}
                placeholder="Enter Quantity"
                size="wide"
                onChange={(e) =>
                  setFormData({ ...formData, Quantity: e.target.value })
                }
                value={formData.Quantity}
              />
            </div>
            {edit ? (
              <div>
                <Input
                  label="Vendor Code"
                  disabled="true"
                  size="wide"
                  value={formData["vendor Code"]}
                ></Input>
              </div>
            ) : (
              <div>
                <DropDown
                  label="Select Vendor Code"
                  options={vendors}
                  size="wide"
                  onChange={(e) => {
                    setFormData({
                      ...formData,
                      "vendor Code":
                        e.target.value === "Select"
                          ? null
                          : vendors.filter((vendor) => {
                              return e.target.value == vendor._id;
                            })[0].name,
                      "Vendor Name":
                        e.target.value === "Select"
                          ? null
                          : vendors.filter((vendor) => {
                              return e.target.value == vendor._id;
                            })[0].Name,
                      vendorId:
                        e.target.value === "Select" ? null : e.target.value,
                    });
                  }}
                ></DropDown>
              </div>
            )}
            <div>
              <Input
                label="Vendor Name"
                disabled="true"
                value={formData["Vendor Name"]}
                size="wide"
              />
            </div>
            <div>
              <Input
                label="Job Id / Project Id"
                placeholder="Enter Job Id / Project Id"
                value={formData["Project Id"]}
                size="wide"
                onChange={(e) =>
                  setFormData({ ...formData, "Project Id": e.target.value })
                }
              />
            </div>
            {formData["Reservation Number"] ||
            formData["Delivery Note Number"] ||
            formData["Gate Pass Number"] ? null : (
              <>
                <div></div>
                <div></div>
              </>
            )}
            <div></div>
          </div>
        </>
      ) : null}
      <div>
        <TextArea
          label="Remark"
          size="wide"
          value={formData["Remark"]}
          onChange={(e) => setFormData({ ...formData, Remark: e.target.value })}
        ></TextArea>
      </div>
      {!edit ? (
        <Button
          label="Submit"
          className="success"
          onClick={modalVisibleForSubmit}
        ></Button>
      ) : (
        <Button
          label="Update"
          className="primary"
          onClick={modalVisibleForEdit}
        ></Button>
      )}
      <Button 
        label="Exit Stock Bulk Upload"
        className="primary"
        onClick={modalBulkUpload}
      />
      {/* <br />
      <br /> */}
      <MaterialTables
        headings={headings}
        data={tableContent}
        title={`Exit List (${props.godown.name})`}
      ></MaterialTables>
    </>
  );
};

export default Exit;
