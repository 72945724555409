import React, { useState, useEffect } from "react";
import * as requests from "../../../api/requests";
import ContainerHeader from "../../../components/containerHeader/ContainerHeader";
import Paper from "../../../components/whiteCard/whiteCard";
import MaterialTables from "../../../components/materialTable/MaterialTables";
import Modal from "../../../components/ui/modal/Modal";
import Loader from "../../../components/spinner/Spinner";
import url, {url_upload} from "../../../constants/url";
import authCheck from '../../../helpers/auth.helpers';

const AllProjects = () => {
  // const { areaAndZone, designation } = authCheck(localStorage.getItem('authCode'));
  const {  designation } = localStorage;
  const  areaAndZone  = JSON.parse(localStorage.getItem("areaAndZone"))

  const [tableContents, setTableContents] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const connLinks = [
    {
      name: "All Reports",
      path: '/reports'
    }
  ]
  const headings = [
    "Area",
    "Description",
    "DOC",
    "DOS",
    "Drawing",
    "Estimate",
    "NotificationNo",
    "PO_Number",
    "PR_Number",
    "ProjectId",
    "ProjectType",
    "ShowCause",
    "ShowCauseDate",
    "State",
    "SubContractorName",
    "SubContractorNumber",
    "Technician",
    "ValidityLLC",
    "Vendor",
    "Jr. Engineer",
  ];
  useEffect(() => {
    setLoading(true);
    setShow(true);
    requests
      .get({ url: "/projects/getProjectList" })
      .then((response) => {
        let tempArr = [];
        let areaList = [];
        areaAndZone.map((area) => {
          areaList.push(area.area._id);
        });
        let data = response.filter((project) =>
          designation == "Engineer" ||
            designation == "GM/DGM"
            ? areaList.includes(project.area._id)
            : true
        );
        data.map((item) => {
          let obj = {
            Area: item.area.areaName,
            Description:
              item.description.length > 100
                ? item.description.slice(0, 100)
                : item.description,
            DOC: new Date(item.doc).toDateString("en-GB"),
            DOS: new Date(item.dos).toDateString("en-GB"),
            Drawing:
              item.drawing === "noimage.jpg" ? (
                "no-uploads"
              ) : (
                  <a
                    href={`${url_upload}/projects/drawing/${item.drawing}`}
                    target="_blank"
                  >
                    View Drawing
                  </a>
                ),
            Estimate:
              item.estimate === "0" ? (
                "0"
              ) : (
                  <a
                    href={`${url_upload}/projects/estimate/${item.estimate}`}
                    target="_blank"
                  >
                    View Estimate
                  </a>
                ),
            NotificationNo: item.notificationNo,
            PO_Number: item.poNumber,
            PR_Number: item.prNumber,
            ProjectId: item.projectId,
            ProjectType: item.projectType,
            ShowCause: item.showcause ? "Issued" : "Not Issued",
            ShowCauseDate: item.showcause ? item.showcauseDate : "-",
            State: item.state,
            SubContractorName: item.subcontractorName
              ? item.subcontractorName
              : "-",
            SubContractorNumber: item.subcontractorNumber
              ? item.subcontractorNumber
              : "-",
            Technician: item.technician.name,
            ValidityLLC: new Date(item.validityLLC).toLocaleDateString("en-GB"),
            Vendor: item.vendor.name,
            "Jr. Engineer": item.jrEngineer.name,
            // zone: item.zone.zoneName,
          };
          tempArr = [...tempArr, obj];
        });
        setTableContents(tempArr);
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        console.log(e);
        setError(true);
        setLoading(false);
      });
  }, []);

  const modalClose = () => {
    setShow(false);
  };

  return (
    <div className="vendorList">
      {loading || error ? (
        <Modal show={show} noHandler={modalClose}>
          {error ? <p>Something went wrong!</p> : <Loader></Loader>}
        </Modal>
      ) : null}
      <ContainerHeader formName="All Projects List" connLinks={connLinks}></ContainerHeader>
      <Paper>
        <MaterialTables
          title="All Projects"
          data={tableContents}
          headings={headings}
          ispdf={false}
        />
      </Paper>
    </div>
  );
};

export default AllProjects;
