import React from 'react'
import './ContainerHeader.css'
import Typography from '@material-ui/core/Typography';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import { useHistory } from 'react-router-dom';
import Link from '@material-ui/core/Link';

const ContainerHeader = (props) => {
const [connLinks, setConnLinks] = React.useState([])
    React.useEffect(() => {
    if(props.connLinks){
        const links = props.connLinks.map(link => {
            return(
                <Link href={link.path} color='inherit'>
                {link.name}
                </Link>
            )
        })
        setConnLinks(links)
        // console.log(links)
    }
}, [])

// const handleBackClick = () => {
//     if (history.length > 1) {
//       history.goBack();
//     } else {
//       // If no history, redirect to a default route
//       history.push('/defaultRoute');
//     }
//   };
  

    
    const history = useHistory()
    return (
        <div className="container-header">
            <div className="form-name">
                <h2>{props.formName}</h2>
            </div>
            {props.formName === 'Dashboard' ? null : (
            <div className="button-crums">
            <div className="buttons">
                <button
                    className="primary"
                    onClick={
                        () => history.goBack()
                    }
                >
                    <i className="fas fa-long-arrow-alt-left"></i>
                </button>

                <div className='refresh'>
                <button
                    className="primary"
                    onClick={() => window.location.reload()}
                >
                    <i className="fa fa-refresh"></i>
                </button>
                </div>
            </div>
            <div className="breadcrum">
                <Breadcrumbs >
                    <Link href="/" color='inherit'>
                        <i className="fas fa-home"></i>
                </Link>
                {connLinks}
                    <Typography color="textPrimary"> {props.formName} </Typography>
                </Breadcrumbs>
            </div>
            </div>  
            )}
        </div>
    )
}

export default ContainerHeader