import React, { useState } from "react";
import Paper from "../../../components/whiteCard/whiteCard";
import ContainerHeader from "../../../components/containerHeader/ContainerHeader";
import Button from "../../../components/inputComponents/Button";
import "./VendorJobs.css";
import DropDown from "../../../components/inputComponents/DropDown";
import * as requests from "../../../api/requests";
import MaterialTables from "../../../components/materialTable/MaterialTables";
import Modal from "../../../components/ui/modal/Modal";
import Loader from "../../../components/spinner/Spinner";
import url, {url_upload} from "../../../constants/url";
import authCheck from "../../../helpers/auth.helpers";

const VendorJobs = () => {
  // const { areaAndZone, designation } = authCheck(localStorage.getItem('authCode'));
  const { designation } = localStorage;
  const  areaAndZone  = JSON.parse(localStorage.getItem("areaAndZone"))

  const [vedorOp, setVendorOp] = useState([
    {
      _id: null,
      name: "Select",
    },
  ]);
  const [vendor, setVendor] = useState([]);
  const [tableContents, setTableContents] = useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const connLinks = [
    {
      name: "All Reports",
      path: '/reports'
    }
  ]
  const headings = [
    "Project Type",
    "Project Id",
    "Zone",
    "Area",
    "PO Number",
    "PR Number",
    "Notification Number",
    "State",
    "Subcontractor",
    "Technician",
    "Vendor",
    "Drawing",
    "Estimate"
  ];

  // React.useEffect(() => {
  //   setLoading(true);
  //   setShow(true);
  //   requests
  //     .post({ url: "/vendor/getVendors" })
  //     .then((data) => {
  //       let tempVendors = [
  //         {
  //           _id: null,
  //           name: "Select",
  //         },
  //       ];
  //       data.map((vendor) => {
  //         tempVendors.push({
  //           _id: vendor._id,
  //           name: vendor.name,
  //         });
  //       });
  //       setVendorOp(tempVendors);
  //       modalClose();
  //       setLoading(false);
  //     })
  //     .catch((e) => {
  //       setError(true);
  //       setLoading(false);
  //     });
  // }, []);
  React.useEffect(() => {
    setLoading(true);
    setShow(true);
    requests
      .get({ url: "/vendors/getVendors" })
      .then((data) => {
        let tempVendors = [
          {
            _id: null,
            name: "Select",
          },
        ];
        data.map((vendor) => {
          tempVendors.push({
            _id: vendor._id,
            name: vendor.name,
          });
        });

        setVendorOp(tempVendors);
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  const modalClose = () => {
    setShow(false);
  };

  function getVendorJobs() {
    setLoading(true);
    setShow(true);
    requests
      .get({ url: "/projects/getProjectList" })
      .then((data) => {
        let tempProjectList = [];
        let zonesArr = [];
        let areaList = [];
        areaAndZone.map((area) => {
          areaList.push(area.area._id);
        });
        let arr = data.filter((project) =>
          designation == "Engineer" ||
            designation == "GM/DGM"
            ? areaList.includes(project.area._id)
            : true
        );
        arr.map((project) => {
          if (project.vendor._id === vendor)
            tempProjectList.push({
              "Project Type": project.projectType,
              "Project Id": project.projectId,
              Zone: project.zone ? project.zone.zoneName : "",
              Area: project.area ? project.area.areaName : "",
              "PO Number": project.poNumber,
              "PR Number": project.prNumber,
              "Notification Number": project.notificationNo,
              State: project.state,
              Subcontractor: project.subcontractorName ? "Yes" : "No",
              Technician: project.technician ? project.technician.name : null,
              Vendor: project.vendor ? project.vendor.name : null,
              Drawing: project.drawing === "noimage.jpg" ? (
                "no-uploads"
              ) : (<a href={`${url_upload}/projects/drawing/${project.drawing}`} target="_blank">
                View Drawing
              </a>
                ),
              Estimate: project.estimate === "0" ? (
                "0"
              ) : (<a href={`${url_upload}/projects/estimate/${project.estimate}`} target="_blank">
                View Estimate
              </a>
                )
            });
        });
        setTableContents(tempProjectList);
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });
  }

  return (
    <div id="vendor-jobs">
      {loading || error ? (
        <Modal show={show} noHandler={modalClose}>
          {error ? <p>Something went wrong!</p> : <Loader></Loader>}
        </Modal>
      ) : null}
      <ContainerHeader formName="Vendor Wise Job List" connLinks={connLinks}> </ContainerHeader>
      <Paper>
        <div className="vendor-jobs-form">
          <div className="fields">
            <div className="field">
              <DropDown
                label="Select Vendor"
                name="vendor"
                size="wide"
                options={vedorOp}
                onChange={(e) => setVendor(e.target.value)}
              />
            </div>
            <br />
            <div className="field">
              <Button
                label="View Jobs"
                className="success"
                onClick={getVendorJobs}
              />
            </div>
          </div>
        </div>
        <br />
        <br />
        {tableContents && tableContents.length > 0 ? (
          <MaterialTables
            headings={headings}
            data={tableContents}
            title={"Jobs assigned to vendor: " + tableContents[0].Vendor}
          />
        ) : (
            "No projects"
          )}
      </Paper>
    </div>
  );
};

export default VendorJobs;
