import React, { useEffect, useState } from "react";
import ContainerHeader from "../../../components/containerHeader/ContainerHeader";
import Paper from "../../../components/whiteCard/whiteCard";
import * as requests from "../../../api/requests";
import MaterialTables from "../../../components/materialTable/MaterialTables";
import Modal from "../../../components/ui/modal/Modal";
import Loader from "../../../components/spinner/Spinner";
import authCheck from "../../../helpers/auth.helpers";

const SubcontractedJobs = () => {
  // const { areaAndZone, designation } = authCheck(localStorage.getItem('authCode'));
  const { designation } = localStorage;
  const  areaAndZone  = JSON.parse(localStorage.getItem("areaAndZone"))

  const [tableContents, setTableContents] = React.useState(null);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const connLinks = [
    {
      name: "All Reports",
      path: '/reports'
    }
  ]
  const headings = [
    "Project Type",
    "Project Id",
    "Zone",
    "Area",
    "PO Number",
    "PR Number",
    "Notification Number",
    "State",
    "Subcontractor",
    "Technician",
    "Vendor",
  ];
  React.useEffect(() => {
    setLoading(true);
    setShow(true);
    requests
      .get({
        url: "/projects/getProjectList",
      })
      .then((response) => {
        let tempProjectList = [];
        let areaList = []
        areaAndZone.map(area=>{
          areaList.push(area.area._id)
        })
        let data = response.filter(
          (project) => project.subcontractorName && (designation=="Engineer"||designation=="GM/DGM"?areaList.includes(project.area._id):true)
        );
        data.map((project) => {
          const path = "/project-details/" + project._id;
          tempProjectList.push({
            "Project Type": project.projectType,
            "Project Id": project.projectId,
            Zone: project.zone ? project.zone.zoneName : "",
            Area: project.area ? project.area.areaName : "",
            "PO Number": project.poNumber,
            "PR Number": project.prNumber,
            "Notification Number": project.notificationNo,
            State: project.state,
            Subcontractor: project.subcontractorName,
            Technician: project.technician ? project.technician.name : null,
            Vendor: project.vendor ? project.vendor.name : null,
          });
        });
        setTableContents(tempProjectList);
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
        console.log(e);
      });
  }, []);

  const modalClose = () => {
    setShow(false);
  };

  return (
    <div id="project-list">
      {loading || error ? (
        <Modal show={show} noHandler={modalClose}>
          {error ? <p>Something went wrong!</p> : <Loader></Loader>}
        </Modal>
      ) : null}
      <ContainerHeader formName="Vendor Engaging Sub-Contractor Job List" connLinks={connLinks}></ContainerHeader>
      <Paper>
        <div className="project-list-table">
          {tableContents && tableContents.length > 0 ? (
            <MaterialTables
              headings={headings}
              data={tableContents}
              title="Sub-Contracted Job List"
            />
          ) : (
            "No projects"
          )}
        </div>
      </Paper>
    </div>
  );
};

export default SubcontractedJobs;
