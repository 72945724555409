import React, { useState, useEffect } from "react";
import * as requests from "../../../api/requests";
import ContainerHeader from "../../../components/containerHeader/ContainerHeader";
import Paper from "../../../components/whiteCard/whiteCard";
import MaterialTables from "../../../components/materialTable/MaterialTables";
import Modal from "../../../components/ui/modal/Modal";
import Loader from "../../../components/spinner/Spinner";
import authCheck from '../../../helpers/auth.helpers';

const NoPr = () => {
  // const { areaAndZone, designation } = authCheck(localStorage.getItem('authCode'));
  const {  designation } = localStorage;
  const  areaAndZone  = JSON.parse(localStorage.getItem("areaAndZone"))
  
  const [tableContents, setTableContents] = useState([]);
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const connLinks = [
    {
      name: "All Reports",
      path: "/reports",
    },
  ];
  const headings = [
    "Project Type",
    "Project Id",
    "Zone",
    "Area",
    "Notification Number",
    "State",
    "Subcontractor",
    "Jr. Engineer",
    "Vendor",
  ];
  useEffect(() => {
    setLoading(true);
    setShow(true);
    requests
      .get({ url: "/projects/getProjectList" })
      .then((data) => {
        let tempProjectList = [];
        let areaList = [];
        areaAndZone.map((area) => {
          areaList.push(area.area._id);
        });
        let dataFin = data.filter(
          (project) =>
            project.prNumber == 0 &&
            (designation == "Engineer" ||
            designation == "GM/DGM"
              ? areaList.includes(project.area._id)
              : true)
        );
        dataFin.map((project) => {
          tempProjectList.push({
            "Project Type": project.projectType,
            "Project Id": project.projectId,
            Zone: project.zone ? project.zone.zoneName : "",
            Area: project.area ? project.area.areaName : "",
            "Notification Number": project.notificationNo,
            State: project.state,
            Subcontractor: project.subcontractorName ? "Yes" : "No",
            "Jr. Engineer": project.jrEngineer ? project.jrEngineer.name : null,
            Vendor: project.vendor ? project.vendor.name : null,
          });
        });
        setTableContents(tempProjectList);
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        console.log('.1')
        setError(true);
        setLoading(false);
      });
  }, []);

  const modalClose = () => {
    setShow(false);
  };

  return (
    <>
      {loading || error ? (
        <Modal show={show} noHandler={modalClose}>
          {error ? <p>Something went wrong!</p> : <Loader></Loader>}
        </Modal>
      ) : null}
      <ContainerHeader
        formName="All Projects List (Without PR)"
        connLinks={connLinks}
      ></ContainerHeader>
      <Paper>
        <div className="project-list-table">
          {tableContents && tableContents.length > 0 ? (
            <MaterialTables
              headings={headings}
              data={tableContents}
              title="All Projects List (Without PR)"
            />
          ) : (
            "No projects"
          )}
        </div>
      </Paper>
    </>
  );
};

export default NoPr;
