import axios from 'axios'
import url from '../constants/url'

export const get = (params) =>{
    return new Promise((resolve,reject)=>{
        axios.get(url+params.url,{
            headers:{...params.headers},
            withCredentials:true
        }).then(data=>{
            resolve(data.data)
        }).catch(e=>{
            reject(e)
        })
        
    })
}

export const post = (params) =>{
    console.log(params)
    return new Promise((resolve,reject)=>{
        axios.post(url+params.url,{...params.body},{
            headers: {...params.headers},
            withCredentials:true
        }).then(data=>{
            resolve(data.data)
        }).catch(e=>{
            reject(e)
        })
    })
}