import React from "react";
import "./App.css";
import Navbar from "./components/navbar/Navbar";
import ProtectedRoute from "./components/protectedRoute/ProtectedRoute";
import Map from "./containers/map/Map";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import Login from "./containers/login/Login";
import SapRoutes from "./routes/Sap";
import EngineerRoutes from "./routes/Engineer";
import JrEngieerRoutes from "./routes/Je";
import GmRoutes from "./routes/Gm";
import TechnicianRoutes from "./routes/Technician";

const authCheck = require('./helpers/auth.helpers');

function App() {
  // const { designation } = authCheck(localStorage.getItem('authCode'))

  // const [isAuth, setIsAuth] = React.useState(true);
  // const [loggedIn, setLoggedIn] = React.useState(
  //   authCheck(localStorage.getItem('authCode')).designation ? true : false
  // );
  const [isAuth, setIsAuth] = React.useState(true);
  const [loggedIn, setLoggedIn] = React.useState(
    localStorage.getItem("designation") ? true : false
  );
  // console.log(designation);
  // console.log(loggedIn)

  let Routes;
  switch (localStorage.getItem("designation")) {
    case "SAP":
      Routes = <SapRoutes></SapRoutes>;
      break;
    case "GM/DGM":
      Routes = <GmRoutes></GmRoutes>;
      break;
    case "Engineer":
      Routes = <EngineerRoutes></EngineerRoutes>;
      break;
    case "Jr. Engineer":
      Routes = <JrEngieerRoutes></JrEngieerRoutes>;
      break;
    case "Technician":
      Routes = <TechnicianRoutes></TechnicianRoutes>;
      break;
    default:
      Routes = null;
      break;
  }
  return (
    <Router>
      {loggedIn
        ? null
        : window.location.pathname.localeCompare("/login") === 0
        ? null
        : (window.location.pathname = "/login")}
      {window.location.pathname.localeCompare("/login") === 0 ? (
        <Login loggedIn={loggedIn} setLoggedIn={setLoggedIn}></Login>
      ) : window.location.pathname.localeCompare("/map") === 0 ? (
        <Map></Map>
      ) : (
        <Navbar>{Routes}</Navbar>
      )}
    </Router>
  );
}

export default App;
