import React, { useEffect, useState } from "react";
import "./AddProject.css";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Paper from "../../components/whiteCard/whiteCard";
import Input from "../../components/inputComponents/Input";
import Button from "../../components/inputComponents/Button";
import DropDown from "../../components/inputComponents/DropDown";
import TextArea from "../../components/inputComponents/TextArea";
import Axios from "axios";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import url from "../../constants/url";
import Alert from "@material-ui/lab/Alert";
import { FormControl, FormControlLabel, FormLabel, Radio, RadioGroup } from '@material-ui/core';


const AddProject = (props) => {
  const [details, setDetails] = useState({
    projectType: "",
    area: "",
    zone: "",
    zoneName: "",
    areaName: "",
    state: "Ongoing",
    installation: "",
    installationName: "",
    notificationNo: 0,
    projectId: "",
    notification: "",
    description: "",
    technician: "",
    vendor: "",
    engineer: "",
    jrEngineer: "",
    dos: "",
    doc: "",
    validityLLC: "",
    prNumber: 0,
    poNumber: 0,
    subcontractorName: "",
    subcontractorNumber: 0,
    remarks: "",
  });
  const [flag, setFlag] = useState(false);
  const [technician, setTechnician] = useState([]);
  const [je, setJe] = useState([]);
  const [eng, setEng] = useState([]);
  const [sub, setSub] = useState(false);
  const [vendor, setVendor] = useState([]);
  const [show, setShow] = useState(false);
  const [zones, setZones] = useState([]);
  const [showModal, setShowModal] = useState(false);
  const [functionToPass, setFunctionToPass] = useState(null);
  const [ques, setQues] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [formErrors, setFormErrors] = useState([]);
  const [projectId, setProjectId] = useState("");
  const [selectedVendor, setSelectedVendor] = useState('');
  const [radiovalue, setRadiovalue] = React.useState('');
  const connLinks = [
    {
      name: "Notifications List",
      path: '/notifications'
    }
  ]
  const listObj = { name: "Select", _id: "select" };

  useEffect(() => {
    setLoading(true);
    setShowModal(true);
    Axios.get(url + "/notifications/" + props.match.params.id)
      .then((response) => {
        let tempObj = { ...details };
        let apiObj = {};
        apiObj = response.data[0];
        tempObj["projectType"] = apiObj["projectType"];
        tempObj["areaName"] = apiObj["area"]["areaName"];
        tempObj["area"] = apiObj["area"]["_id"];
        tempObj["installation"] = apiObj["installation"]["_id"];
        tempObj["installationName"] = apiObj["installation"]["name"];
        tempObj["notificationNo"] = apiObj["notificationNumber"];
        tempObj["projectId"] = apiObj["projectId"];
        tempObj["notification"] = props.match.params.id;
        tempObj["description"] = apiObj["description"];
        setDetails(tempObj);
        Axios.get(url + "/areas/" + apiObj["area"]["_id"])
          .then((response) => {
            let zone = response.data["zones"];
            zone.unshift(listObj);
            setZones(zone);
            setFlag(true);
            modalClose();
            setLoading(false);
          })
          .catch((err) => {
            setError(true);
            setLoading(false);
          });
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  }, []);

  const modalVisible = () => {
    setError(false);
    let errors = [];
    let toCheck = {};
    toCheck = {
      zone: "ZONE",
      vendor: "VENDOR",
      dos: "DOS",
      doc: "DOC",
      technician: "TECHNICIAN",
      jrEngineer: "JR. ENGINEER",
      engineer: "ENGINEER",
    };
    if (sub) {
      toCheck = {
        ...toCheck,
        subcontractorName: "Sub-coordinator/POA holder name",
        subcontractorNumber: "Sub-coordinator/POA holder No.",
      };
    }

    Object.keys(toCheck).map((key) => {
      if (!details[key]) {
        errors.push(toCheck[key] + " is required");
      }
    });
    if (details.dos > details.doc) errors.push("DOS must be less than DOC");
    if (errors.length > 0) {
      setFormErrors(errors);
      return;
    }
    setFormErrors([]);
    setQues("Do you want to assign this job?");
    setFunctionToPass(() => () => submitHandler());
    setShowModal(true);
  };

  const modalClose = () => {
    setShowModal(false);
  };

  const handleChangeradio = (event) => {
    setRadiovalue(event.target.value);
    if (event.target.value === 'yes')
    {
      setSub(true)
    }
    else
    {
      setSub(false)
    }
  };

  const inputChangeHandler = (event, val) => {
    setDetails({
      ...details,
      [val]: event.target.value,
    });
    if(val === "vendor"){
      if(event.target.value === "Select"){}
      else{
        let selVendor = vendor.filter((v) => {
          console.log(v._id)
          return event.target.value == v._id;
        })[0].Name

        setSelectedVendor(selVendor);
        console.log(selectedVendor)
      }
    }
    if (val === "zone") {
      setError(false);
      setLoading(true);
      setShowModal(true);
      Axios.post(url + "/users/findUsersInZone", {
        zoneId: event.target.value,
      })
        .then((response) => {
          let tempArr = [];
          tempArr = response.data["technician"];
          tempArr.unshift({ ...listObj });
          setTechnician(tempArr);
          tempArr = response.data["je"];
          tempArr.unshift({ ...listObj });
          setJe(tempArr);
          tempArr = response.data["engineer"];
          tempArr.unshift({ ...listObj });
          setEng(tempArr);
          tempArr = [];
          console.log(response)
          response.data["vendors"].map((vendor) => {
            let tempObj = {};
            tempObj["name"] = `(${vendor["code"]}) ${vendor["name"]}`;
            tempObj["Name"] = vendor["name"];
            tempObj["id"] = vendor["code"];
            tempObj["Code"] = vendor["code"];
            tempObj["_id"] = vendor["_id"];
            tempObj["status"] = vendor["status"]

            tempArr = [...tempArr, tempObj];
          })
          

          tempArr.sort((a, b) => parseInt(a.Code) - parseInt(b.Code));
          tempArr = [{ name: "Select", id: "Select" }, ...tempArr];
          tempArr = tempArr.filter((vendor) => vendor.status === "Active");
          tempArr.unshift({ ...listObj });
          console.log(tempArr)

          setVendor(tempArr);
          setShow(true);
          modalClose();
          setLoading(false);
        })
        .catch((err) => {
          setError(true);
          setLoading(false);
          window.location.reload();
        });
    }
  };

  const submitHandler = () => {
    setLoading(true);
    Axios.post(url + "/projects/createProject", details)
      .then((response) => {
        setLoading(false);
        setProjectId(response.data.projectId);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };

  const subcoHandler = (event) => {
    if (event.target.value == "Yes") setSub(true);
    else setSub(false);
  };
  return (
    <div id="add-project">
      <Modal show={showModal} noHandler={modalClose}>
        {!loading ? (
          !error ? (
            projectId === "" ? (
              <ConfirmContent
                yesHandler={functionToPass}
                noHandler={modalClose}
                question={ques}
              ></ConfirmContent>
            ) : (
              <p>Project created with project ID: {projectId}</p>
            )
          ) : (
            <p>Something went wrong!</p>
          )
        ) : (
          <Loader></Loader>
        )}
      </Modal>
      <ContainerHeader formName="Job Assignment" connLinks={connLinks}></ContainerHeader>
      {flag ? (
        <Paper>
          <div className="add-project-form">
            <div className="add-project-grid1">
              <div className="fields">
                <div className="field">
                  <Input
                    name="project-type"
                    label="Project Type *"
                    disabled={true}
                    size="wide"
                    value={details.projectType}
                  />
                </div>
                <div className="field">
                  <Input
                    name="project-id"
                    label="Project ID *"
                    disabled={true}
                    size="wide"
                    value={details.projectId}
                  />
                </div>
                <div className="field">
                  <Input
                    name="notification-no"
                    label="Notification No *"
                    disabled={true}
                    size="wide"
                    value={details.notificationNo}
                  />
                </div>
                <div className="field">
                  <Input
                    name="installation"
                    label="Installation *"
                    disabled={true}
                    size="wide"
                    value={details.installationName}
                  />
                </div>
                <div className="field">
                  <Input
                    name="area"
                    label="Area *"
                    disabled={true}
                    size="wide"
                    value={details.areaName}
                  />
                </div>
              </div>
            </div>
            <div className="fields">
              <div className="field">
                <TextArea
                  label="Description of Project *"
                  name="description"
                  size="wide"
                  disabled={true}
                  value={details.description}
                />
              </div>
            </div>
            <div className="add-project-grid2">
              <div className="fields">
                <div className="field">
                  <DropDown
                    size="wide"
                    label="Zone *"
                    options={zones}
                    onChange={(e) => inputChangeHandler(e, "zone")}
                  />
                </div>
                {show ? (
                  <>
                  <div className="field">
                    <DropDown
                      size="wide"
                      label="Assign to Vendor Code*"
                      options={vendor}
                      onChange={(e) => inputChangeHandler(e, "vendor")}
                    />
                  </div>
                  <div className="field">
                    <Input 
                      disabled = {true}
                      size = "wide"
                      label = "Vendor Name"
                      value={selectedVendor}
                    />
                  </div>
                  </>
                ) : null}
                {/* < /> */}
                <div className="field">
                  <FormControl component="fieldset">
                    <FormLabel style={{ fontFamily:'Roboto,sans-serif', color: 'black', fontWeight:300}}>Sub-contractor Engaged/POA*</FormLabel>
                    <RadioGroup row aria-label="subcontractor" name="subcontractor" value={radiovalue} onChange={handleChangeradio} style={{ fontFamily:'Roboto,sans-serif', color: 'black', fontWeight:300}}>
                      <FormControlLabel value="yes" control={<Radio color="primary" />} label={<span style={{ fontFamily: 'Roboto, sans-serif', color: 'black', fontWeight: 300 }}>Yes</span>} />
                      <FormControlLabel value="no" control={<Radio color="primary" />} label={<span style={{ fontFamily: 'Roboto, sans-serif', color: 'black', fontWeight: 300 }}>No</span>} />
                    </RadioGroup>
                  </FormControl>
                </div>
                {sub ? (
                  <>
                  <div className="field">
                    <Input 
                      disabled = {true}
                      size = "wide"
                      label = "Sub-contractor/POA holder name"
                      value={details.subcontractorName}
                    />
                  </div>
                  <div className="field">
                    <Input 
                      disabled = {true}
                      size = "wide"
                      label = "Sub-contractor/POA holder contact no"
                      value={details.subcontractorNumber}
                    />
                  </div>
                  </>
                ) : null}
                <div className="field">
                  <Input
                    type="date"
                    name="dos"
                    placeholder="Select Date"
                    label="DOS *"
                    size="wide"
                    onChange={(e) => inputChangeHandler(e, "dos")}
                  />
                </div>
                <div className="field">
                  <Input
                    type="date"
                    name="doc"
                    placeholder="Select Date"
                    label="DOC *"
                    size="wide"
                    onChange={(e) => inputChangeHandler(e, "doc")}
                  />
                </div>
                {show ? (
                  <div className="field">
                    <DropDown
                      size="wide"
                      label="Assign to Technician *"
                      options={technician}
                      onChange={(e) => inputChangeHandler(e, "technician")}
                    />
                  </div>
                ) : null}
                {show ? (
                  <div className="field">
                    <DropDown
                      size="wide"
                      label="Assign to Jr. Engineer *"
                      options={je}
                      onChange={(e) => inputChangeHandler(e, "jrEngineer")}
                    />
                  </div>
                ) : null}
                {show ? (
                  <div className="field">
                    <DropDown
                      size="wide"
                      label="Assign to Engineer *"
                      options={eng}
                      onChange={(e) => inputChangeHandler(e, "engineer")}
                    />
                  </div>
                ) : null}
              </div>
              <div className="text-area">
                <TextArea
                  label="Remarks"
                  name="remarks"
                  size="wide"
                  onChange={(e) => inputChangeHandler(e, "remarks")}
                />
              </div>
            </div>
            {formErrors.map((error) => {
              return <Alert severity="info">{error}</Alert>;
            })}
            <hr />
            {projectId === "" ? (
              <Button
                className="success"
                label="Submit"
                onClick={modalVisible}
              ></Button>
            ) : null}
          </div>
        </Paper>
      ) : null}
    </div>
  );
};

export default AddProject;
