import React, { useEffect, useState } from "react";
import "./TallyBook.css";
import ContainerHeader from "../../components/containerHeader/ContainerHeader";
import Paper from "../../components/whiteCard/whiteCard";
import Input from "../../components/inputComponents/Input";
import Button from "../../components/inputComponents/Button";
import DropDown from "../../components/inputComponents/DropDown";
import TextArea from "../../components/inputComponents/TextArea";
import Table from "../../components/table/unsortedTable/UnsortedTable";
import EditDelete from "../../components/editDelete/EditDelete";
import Select from "@material-ui/core/Select";
import * as requests from "../../api/requests";
import BlankTable from "../../components/table/blankTable/BlankTable";
import url from "../../constants/url";
import axios from "axios";
import Modal from "../../components/ui/modal/Modal";
import ConfirmContent from "../../components/ui/confirmContent/ConfirmContent";
import Loader from "../../components/spinner/Spinner";
import IconButton from "../../components/inputComponents/IconButton";
import { Link } from "react-router-dom";
import { PDFViewer } from "@react-pdf/renderer";
import TallyReportPrint from "../../components/tallyReport/SinglePage";
import authCheck from "../../helpers/auth.helpers";

const TallyBook = (props) => {
  // const { areaAndZone, designation } = authCheck(localStorage.getItem('authCode'));
  const { designation } = localStorage;
  const  areaAndZone  = JSON.parse(localStorage.getItem("areaAndZone"))

  const [todayProgress, setTodayProgerss] = React.useState({});
  const [allProgress, setAllProgress] = React.useState({});
  const [projectDetails, setProjectDetails] = React.useState({});
  const [machineries, setMachineries] = React.useState([]);
  const [materials, setMaterials] = React.useState([]);
  const [materialsDD, setMaterialsDD] = React.useState(null);
  const [materialFormData, setMaterialFormData] = React.useState({});
  const [verify, setVerify] = useState(false);
  const [show, setShow] = useState(false);
  const [functionToPass, setFunctionToPass] = useState(null);
  const [ques, setQues] = useState("");
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(false);
  const [update, setUpdate] = useState(false);
  const [machineryFormData, setMachineryFormData] = React.useState({
    type: "",
    name: "",
    number: "",
  });
  const [toSubmit, setToSubmit] = React.useState({});
  const [machines, setMachines] = React.useState([]);
  const [cummulativeConsumption, setCummulativeConsumption] = React.useState(
    []
  );
  const [technicalDoc, setTechnicalDoc] = React.useState();
  const [sitePhoto, setSitePhoto] = React.useState();
  const [showPdf, setShowPdf] = React.useState(false);
  const [currentProject, setCurrentProject] = React.useState({});
  const connLinks = [
    {
      name: "Project list",
      path: "/projects",
    },
    {
      name: "Daliy Progress",
    },
  ];

  const submitTechDoc = () => {
    setLoading(true);
    const file = technicalDoc;
    let data = new FormData();
    data.append("file", file);
    axios
      .patch(
        url +
          "/workProgress/tally/techDoc/" +
          todayProgress._id +
          "/" +
          allProgress._id,
        data
      )
      .then((data) => {
        modalClose();
        setLoading(false);
        setTechnicalDoc(null);
        setUpdate(!update);
      })
      .catch((err) => {
        console.log(err);
        setError(true);
        setLoading(false);
      });
  };
  const submitSitePhoto = () => {
    setLoading(true);
    const file = technicalDoc;
    const data = new FormData();
    data.append("file", sitePhoto);
    axios
      .patch(
        url +
          "/workProgress/tally/sitePhoto/" +
          todayProgress._id +
          "/" +
          allProgress._id,
        data
      )
      .then((data) => {
        modalClose();
        setLoading(false);
        setSitePhoto(null);
        setUpdate(!update);
      })
      .catch((err) => {
        setError(true);
        setLoading(false);
      });
  };
  const typeOfMachinary = [
    { name: "Select type of machinary", _id: "Select type of machinary" },
    { name: "Company machinary", _id: "Company machinary" },
    { name: "Contractor machinary", _id: "Contractor machinary" },
  ];

  const modalVisible = () => {
    setError(false);
    setFunctionToPass(() => () => submitForm());
    setQues("Do you want submit this day's progress?");
    setShow(true);
  };

  const modalClose = () => {
    setShow(false);
  };

  const formattedDate = (dateObj) => {
    const date = new Date(dateObj);
    const year = date.getFullYear();
    let month = date.getMonth() + 1;
    let dt = date.getDate();

    if (dt < 10) {
      dt = "0" + dt;
    }
    if (month < 10) {
      month = "0" + month;
    }
    return dt + "/" + month + "/" + year;
  };

  React.useEffect(() => {
    setLoading(true);
    setShow(true);
    requests
      .get({
        url:
          "/projects/projectDetails?projectId=" + props.match.params.projectId,
      })
      .then((data) => {
        // Note: todayProgress and progressToday are two different things
        let progressToday = data.progress.perDayProgress.filter(
          (perDayProgress) => {
            return (
              JSON.stringify(perDayProgress._id) ===
              JSON.stringify(props.match.params.dateId)
            );
          }
        );
        if (designation === "Engineer")
          setVerify(progressToday[0].approvedByEngineer);
        else if (designation === "Jr. Engineer")
          setVerify(progressToday[0].approvedByJe);
        else if (designation === "Technician")
          setVerify(progressToday[0].approvedByTech);
        let tempArr = [];
        progressToday[0].machineryUsed.map((machine) => {
          let tempObj = {};
          tempObj["Type Of Machinery"] = machine.typeOfMachine;
          tempObj["Description Machinery"] = machine.name;
          tempObj["No. of Units Of Machinery Used"] = machine.quantity;
          tempObj["Actions"] = (
            <IconButton
              iconClass="fas fa-trash-alt"
              className="success"
              onClick={() => deleteMachinery(machine["name"])}
              hint="Delete"
            ></IconButton>
          );
          tempArr = [...tempArr, tempObj];
        });
        setMachines(tempArr);
        let temp = [];
        temp.push({
          name: "Select Material",
          _id: "0000",
          unit: "",
          quantity: 0,
        });
        data.materials.map((material) => {
          temp.push({
            name: material.material.description,
            _id: material._id,
            unit: material.material.unit,
            quantity: material.cummulative,
          });
        });
        let progress = 0;
        data.progress.perDayProgress.map((pro) => {
          progress = progress + pro.progress;
        });
        setMaterials(progressToday[0].materialUsed);
        setAllProgress({ ...data.progress, totalProgress: progress });
        setTodayProgerss(progressToday[0]);
        setProjectDetails({ ...data, progress: {} });
        setMaterialsDD(temp);
      })
      .catch((e) => {});
    requests
      .get({ url: "/machinery/getMachineryList" })
      .then((data) => {
        let temp = [];
        temp.push({ name: "Select Machinery", _id: "0000" });
        data.map((machinery) => {
          temp.push({
            name: machinery.description,
            _id: machinery.description,
          });
        });
        setMachineries(temp);
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });
  }, []);
  React.useEffect(() => {
    setLoading(true);
    setShow(true);
    requests
      .get({
        url:
          "/projects/projectDetails?projectId=" + props.match.params.projectId,
      })
      .then((data) => {
        // Note: todayProgress and progressToday are two different things
        let progressToday = data.progress.perDayProgress.filter(
          (perDayProgress) => {
            return (
              JSON.stringify(perDayProgress._id) ===
              JSON.stringify(props.match.params.dateId)
            );
          }
        );
        if (designation === "Engineer")
          setVerify(progressToday[0].approvedByEngineer);
        else if (designation === "Jr. Engineer")
          setVerify(progressToday[0].approvedByJe);
        else if (designation === "Technician")
          setVerify(progressToday[0].approvedByTech);
        let tempArr = [];
        progressToday[0].machineryUsed.map((machine) => {
          let tempObj = {};
          tempObj["Type Of Machinery"] = machine.typeOfMachine;
          tempObj["Description Machinery"] = machine.name;
          tempObj["No. of Units Of Machinery Used"] = machine.quantity;
          tempObj["Actions"] = (
            <IconButton
              iconClass="fas fa-trash-alt"
              className="success"
              onClick={() => deleteMachinery(machine["name"])}
              hint="Delete"
            ></IconButton>
          );
          tempArr = [...tempArr, tempObj];
        });
        setMachines(tempArr);
        let temp = [];
        temp.push({
          name: "Select Material",
          _id: "0000",
          unit: "",
          quantity: 0,
        });
        data.materials.map((material) => {
          temp.push({
            name: material.material.description,
            _id: material._id,
            unit: material.material.unit,
            quantity: material.cummulative,
          });
        });
        let progress = 0;
        data.progress.perDayProgress.map((pro) => {
          progress = progress + pro.progress;
        });
        setMaterials(progressToday[0].materialUsed);
        setAllProgress({ ...data.progress, totalProgress: progress });
        setTodayProgerss(progressToday[0]);
        setProjectDetails({ ...data, progress: {} });
        setMaterialsDD(temp);
      })
      .catch((e) => {});
    requests
      .get({ url: "/machinery/getMachineryList" })
      .then((data) => {
        let temp = [];
        temp.push({ name: "Select Machinery", _id: "0000" });
        data.map((machinery) => {
          temp.push({
            name: machinery.description,
            _id: machinery.description,
          });
        });
        setMachineries(temp);
        modalClose();
        setLoading(false);
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
      });
  }, [update]);
  React.useEffect(() => {
    let temp = {};
    let x = materialsDD
      ? materialsDD.map((material) => {
          temp = { ...temp, [material._id]: 0 };
        })
      : null;
    if (allProgress.perDayProgress)
      allProgress.perDayProgress.map((day) => {
        day.materialUsed.map((dayMaterial) => {
          materials.map((material) => {
            if (dayMaterial._id === material._id) {
              temp[material._id] =
                parseInt(temp[material._id]) + parseInt(dayMaterial.consumed);
            }
          });
        });
      });
    setCummulativeConsumption(temp);
  }, [materialsDD, materials]);

  const headings = [
    "S. No.",
    "Type Of Machinery",
    "Description Machinery",
    "No. of Units Of Machinery Used",
    "Actions",
  ];

  const addMaterialFunc = () => {
    const material = materialsDD.filter((mat) => {
      return mat._id == materialFormData.material;
    })[0];
    if (cummulativeConsumption[materialFormData.material]) {

    }
    let temp = [...materials];
    let flag = false;
    temp.map((material) => {
      if (material._id == materialFormData.material) {
        flag = true;
        if (
          cummulativeConsumption[materialFormData.material] +
            parseInt(materialFormData.consumed) >
          material.quantity
        ) {
          alert("Not enough material issued");
          return;
        }
        material.consumed =
          parseInt(material.consumed) + parseInt(materialFormData.consumed);
      }
    });
    if (flag) {
      let todayProgressTemp = { ...todayProgress };
      todayProgressTemp.materialUsed = temp;
      let allProgressTemp = { ...allProgress };
      allProgressTemp.perDayProgress.map((day) => {
        if (day._id === todayProgress._id) {
          day.materialUsed = temp;
        }
      });
      setAllProgress(allProgressTemp);
      setTodayProgerss(todayProgressTemp);
      setMaterials(temp);
    } else {
      if (
        cummulativeConsumption[materialFormData.material] +
          parseInt(materialFormData.consumed) >
        material.quantity
      ) {
        alert("Not enough material issued");
        return;
      }
      let todayProgressTemp = { ...todayProgress };

      todayProgressTemp.materialUsed = [
        { ...material, consumed: materialFormData.consumed },
      ].concat(materials);
      let allProgressTemp = { ...allProgress };
      allProgressTemp.perDayProgress.map((day) => {
        if (day._id === todayProgress._id) {
          day.materialUsed = [
            { ...material, consumed: materialFormData.consumed },
          ].concat(materials);
        }
      });
      setAllProgress(allProgressTemp);
      setTodayProgerss(todayProgressTemp);
      setMaterials(
        [{ ...material, consumed: materialFormData.consumed }].concat(materials)
      );
    }
  };

  const addMachineryHandler = () => {
    let tempMachines = [];
    tempMachines.push({
      "Type Of Machinery": machineryFormData["type"],
      "Description Machinery": machineryFormData["name"],
      "No. of Units Of Machinery Used": machineryFormData["number"],
    });
    setMachines(tempMachines.concat(machines));
  };

  const deleteMachinery = (name) => {
    let updatedMachines = machines.filter((machine) => {
      return machine["Description Machinery"] !== name;
    });
    setMachines(updatedMachines);
  };

  let machineriesForTable = [];
  machines.map((machine) => {
    machineriesForTable.push({
      "Type Of Machinery": machine["Type Of Machinery"],
      "Description Machinery": machine["Description Machinery"],
      "No. of Units Of Machinery Used":
        machine["No. of Units Of Machinery Used"],
      Action: (
        <IconButton
          iconClass="fas fa-trash-alt"
          className="success"
          onClick={() => deleteMachinery(machine["Description Machinery"])}
          hint="Delete"
        ></IconButton>
      ),
    });
  });
  let materialsForTable = [];
  materials.map((material) => {
    // console.log(cummulativeConsumption[material._id])
    materialsForTable.push({
      Material: material.name,
      Unit: material.unit,
      "Total Issued Quantity": material.quantity,
      "Today's consumption": material.consumed,
      "Balance Quantity":
        material.quantity - cummulativeConsumption[material._id],
      "Total Consumed": cummulativeConsumption[material._id],
    });
  });

  const submitForm = () => {
    let machineryUsed = [];
    setLoading(true);
    machines.map((machine) => {
      machineryUsed.push({
        typeOfMachine: machine["Type Of Machinery"],
        name: machine["Description Machinery"],
        quantity: parseInt(machine["No. of Units Of Machinery Used"]),
      });
    });

    let finalValue = {
      ...todayProgress,
      materialUsed: materials,
      machineryUsed,
    };
    let dataToSubmit = { ...allProgress };
    let temp = [];
    allProgress.perDayProgress.map((day) => {
      if (day._id == finalValue._id) {
        temp.push(finalValue);
      } else {
        temp.push(day);
      }
    });
    dataToSubmit = { ...dataToSubmit, perDayProgress: temp };
    let updatedProgress = 0;
    allProgress.perDayProgress.forEach((day) => {
      if (todayProgress._id === day._id) {
        updatedProgress = updatedProgress + parseInt(todayProgress.progress);
      } else {
        updatedProgress = updatedProgress + parseInt(day.progress);
      }
    });
    if (updatedProgress > 100) {
      alert("Total progress must be less than 100%!!");
      setLoading(false);
      return;
    }
    axios
      .patch(
        url + "/workProgress/updateWorkProgress/" + dataToSubmit._id,
        dataToSubmit
      )
      .then((data) => {
        modalClose();
        setLoading(false);
        props.history.goBack();
      })
      .catch((e) => {
        setError(true);
        setLoading(false);
        console.log(e);
      });
  };

  return (
    <div id="tally-book">
      <Modal show={show} noHandler={modalClose}>
        {!loading ? (
          !error ? (
            <ConfirmContent
              yesHandler={functionToPass}
              noHandler={modalClose}
              question={ques}
            ></ConfirmContent>
          ) : (
            <p>Something went wrong!</p>
          )
        ) : (
          <Loader></Loader>
        )}
      </Modal>
      <ContainerHeader
        formName="Tally Book"
        connLinks={connLinks}
      ></ContainerHeader>
      <Paper>
        {verify ? (
          <p style={{ color: "red" }}>
            You have already verified the progress for this day. You can only
            view it now.
          </p>
        ) : null}
        <div className="project-details">
          <div className="field">
            <Input
              name="date-of-entry"
              label="Date OF Entry"
              size="wide"
              value={formattedDate(todayProgress.dateOfEntry)}
              disabled="true"
            />
          </div>
          <div className="field">
            <Input
              name="project-type"
              label="Project Type"
              value={projectDetails.projectType}
              disabled="true"
              size="wide"
            />
          </div>
          <div className="field">
            <Input
              name="project-id"
              label="Project ID"
              value={projectDetails.projectId}
              disabled="true"
              size="wide"
            />
          </div>
          <div className="field">
            <Input
              name="notification"
              label="Notification No/Contract No"
              value={projectDetails.notificationNo}
              size="wide"
              disabled="true"
            />
          </div>

          <div className="field">
            <Input
              name="installation"
              label="Installation"
              value={
                projectDetails.installation
                  ? projectDetails.installation.name
                  : null
              }
              disabled="true"
              size="wide"
            />
          </div>
          <div className="field">
            <Input
              name="area"
              label="Area"
              value={projectDetails.area ? projectDetails.area.areaName : null}
              disabled="true"
              size="wide"
            />
          </div>
          <div className="field">
            <Input
              name="zone"
              label="Zone"
              value={projectDetails.zone ? projectDetails.zone.zoneName : null}
              disabled="true"
              size="wide"
            />
          </div>
          <div className="field">
            <Input
              name="dos"
              label="DOS"
              size="wide"
              value={formattedDate(projectDetails.dos)}
              disabled="date"
              disabled="true"
            />
          </div>

          <div className="field">
            <Input
              name="doc"
              label="DOC"
              size="wide"
              value={formattedDate(projectDetails.doc)}
              disabled="date"
              disabled="true"
            />
          </div>
          <div className="field">
            <Input
              name="assign-to-vendor"
              label="Assign To Vendor"
              value={projectDetails.vendor ? projectDetails.vendor.name : null}
              disabled="true"
              size="wide"
            />
          </div>
          <div className="field">
            <Input
              name="assign-to-technician"
              label="Assign To Technician"
              value={
                projectDetails.technician
                  ? projectDetails.technician.name
                  : null
              }
              disabled="true"
              size="wide"
            />
          </div>
          <div className="field">
            <Input
              name="assign-to-je"
              label="Assign to Jr. Engineer"
              value={
                projectDetails.jrEngineer
                  ? projectDetails.jrEngineer.name
                  : null
              }
              size="wide"
              disabled="true"
            />
          </div>

          {projectDetails.subcontractorName &&
          projectDetails.subcontractorName.length !== 0 ? (
            <>
              <div className="field">
                <Input
                  name="subcontractor-name"
                  label="Subcontractor Name"
                  value="abc ltd."
                  disabled="true"
                  size="wide"
                />
              </div>
              <div className="field">
                <Input
                  name="subcontractor-phone"
                  label="Subcontractor Phone No"
                  value="1234567890"
                  size="wide"
                  disabled="true"
                />
              </div>
            </>
          ) : null}
        </div>

        <TextArea
          name="description"
          label="Description Of Project"
          rows="100"
          value={projectDetails.description}
          size="wide"
          disabled="true"
        />

        <div className="daily-progress-form">
          <div className="field">
            <Input
              name="assign-to-engineer"
              label="Assign To Engineer"
              value={
                projectDetails.engineer ? projectDetails.engineer.name : null
              }
              size="wide"
              disabled="true"
            />
          </div>
          <div className="field">
            <Input
              name="work-permitted"
              label="Work Permit Issued"
              value="Yes"
              size="wide"
              value={todayProgress.workPermitted}
              onChange={(event) => {
                setTodayProgerss({
                  ...todayProgress,
                  workPermitted: event.target.value,
                });
              }}
            />
          </div>
          <div className="field">
            <Input
              name="subcontractor-engaged"
              label="Subcontractor Engaged"
              value={
                projectDetails.subcontractorName &&
                projectDetails.subcontractorName.length !== 0
                  ? "Yes"
                  : "No"
              }
              size="wide"
              disabled="true"
            />
          </div>
          {/* <div className="field">
            <Input
              name="labour-licence-validity"
              label="Labour Licence Validity"
              value="08-08-2020"
              size="wide"
              type="date"
              disabled="true"
            />
          </div> */}
          <div className="field">
            <DropDown
              label="Status"
              size="wide"
              options={[
                {
                  name:
                    todayProgress && todayProgress.status
                      ? todayProgress.status
                      : "Select Current Status",
                },
                { name: "Ongoing" },
                { name: "Halt" },
                { name: "Completed" },
              ]}
              onChange={(event) => {
                setTodayProgerss({
                  ...todayProgress,
                  status: event.target.value,
                });
              }}
            />
          </div>
          <div className="field">
            <Input
              name="upload-techinical-document"
              label="Upload Techinical Document"
              type="file"
              onChange={(event) => {
                const file = event.target.files[0];
                setTechnicalDoc(file);
              }}
            />
            <div style={{ marginTop: "-15px", marginBottom: "25px" }}>
              {!verify ? (
                <Button
                  label="Upload"
                  className="success"
                  onClick={submitTechDoc}
                />
              ) : null}

              {todayProgress.technicalDoc &&
              todayProgress.technicalDoc.length > 0 ? (
                <Link
                  to={{
                    pathname: "/tallyDocs",
                    state: { ...todayProgress, progressId: allProgress._id },
                  }}
                >
                  <Button label="View All" className="success" />
                </Link>
              ) : null}
            </div>
          </div>
          <div className="field">
            <Input
              name="upload-stie-photograph"
              label="Upload Site Photograph"
              size="wide"
              type="file"
              onChange={(event) => {
                const file = event.target.files[0];
                setSitePhoto(file);
              }}
            />
            <div style={{ marginTop: "-15px", marginBottom: "25px" }}>
              {!verify ? (
                <Button
                  label="Upload"
                  className="success"
                  onClick={submitSitePhoto}
                />
              ) : null}

              {todayProgress.sitePhoto && todayProgress.sitePhoto.length > 0 ? (
                <Link
                  to={{
                    pathname: "/tallyDocs",
                    state: { ...todayProgress, progressId: allProgress._id },
                  }}
                >
                  <Button label="View All" className="success" />
                </Link>
              ) : null}
            </div>
          </div>
          <div className="field">
            <DropDown
              label="Weather"
              size="wide"
              options={[
                {
                  name:
                    todayProgress && todayProgress.weather
                      ? todayProgress.weather
                      : "Select Weather",
                },
                { name: "Rainy" },
                { name: "Clear" },
              ]}
              onChange={(event) => {
                setTodayProgerss({
                  ...todayProgress,
                  weather: event.target.value,
                });
              }}
            />
          </div>
          <div className="field">
            <Input
              label="Number Of Labour Engagged"
              size="wide"
              value={todayProgress.numberOfLabours}
              onChange={(event) => {
                setTodayProgerss({
                  ...todayProgress,
                  numberOfLabours: event.target.value,
                });
              }}
            />
          </div>
          <div className="field">
            <Input
              label="Progress Till Now in (%)"
              size="wide"
              value={allProgress.totalProgress + "%"}
              disabled="true"
            />
          </div>
          <div className="field">
            <Input
              label="Today's Progress in (%)"
              size="wide"
              value={todayProgress.progress}
              onChange={(event) => {
                setTodayProgerss({
                  ...todayProgress,
                  progress: event.target.value,
                });
              }}
            />
          </div>
          <div className="field">
            <TextArea
              label="Instruction To Contractor"
              size="wide"
              value={todayProgress.instructionToContractor}
              onChange={(event) => {
                setTodayProgerss({
                  ...todayProgress,
                  instructionToContractor: event.target.value,
                });
              }}
            />
          </div>
          <div className="field">
            <TextArea
              label="Saftey/Remarks"
              size="wide"
              value={todayProgress.saftey}
              onChange={(event) => {
                setTodayProgerss({
                  ...todayProgress,
                  saftey: event.target.value,
                });
              }}
            />
          </div>
          <div className="field">
            <TextArea
              label="Hindrance"
              size="wide"
              value={todayProgress.hinderance}
              onChange={(event) => {
                setTodayProgerss({
                  ...todayProgress,
                  hinderance: event.target.value,
                });
              }}
            />
          </div>
        </div>
        <div className="field">
          <TextArea
            label="Details of daily work executed"
            size="wide"
            value={todayProgress.detailsOfWork}
            rows="100px"
            onChange={(event) => {
              setTodayProgerss({
                ...todayProgress,
                detailsOfWork: event.target.value,
              });
            }}
          />
        </div>
        <h2>Machinery used</h2>
        {machines.length !== 0 ? (
          <Table contents={machineriesForTable}></Table>
        ) : (
          <BlankTable headings={headings}></BlankTable>
        )}
        <div className="machinery-user-form">
          <p>Type Of Machinery</p>
          <DropDown
            size="wide"
            options={typeOfMachinary}
            name="type"
            onChange={(event) => {
              setMachineryFormData({
                ...machineryFormData,
                type: event.target.value,
              });
            }}
          />
          <p>Description Machinery</p>
          <DropDown
            size="wide"
            name="name"
            options={machineries}
            onChange={(event) => {
              setMachineryFormData({
                ...machineryFormData,
                name: event.target.value,
              });
            }}
          />
          <p>No. of Units Of Machinery Used</p>
          <div>
            <Input
              size="wide"
              name="number"
              onChange={(event) => {
                setMachineryFormData({
                  ...machineryFormData,
                  number: event.target.value,
                });
              }}
            />
          </div>
        </div>
        <center>
          {!verify ? (
            <Button
              className="success"
              label="Add"
              onClick={() => addMachineryHandler()}
            ></Button>
          ) : null}
        </center>
        <div style={{ display: "flex", flexFlow: "row", marginTop: "80px" }}>
          <h2>Material Used</h2>
          <div style={{ marginLeft: "15px", marginTop: "17px" }}>
            <Link to={"/materials/" + props.match.params.projectId}>
              <Button
                label="View issued materials list"
                className="success"
              ></Button>
            </Link>
          </div>
        </div>
        {materialsForTable.length > 0 ? (
          <Table contents={materialsForTable}></Table>
        ) : null}
        <div className="material-user-form">
          <p style={{ marginTop: "30px" }}>Material</p>
          <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
            <DropDown
              size="wide"
              options={materialsDD ? materialsDD : []}
              onChange={(event) => {
                let thisMaterial = {};
                materialsDD.map((mat) => {
                  if (mat._id == event.target.value) {
                    thisMaterial = {
                      name: mat.name,
                      quantity: mat.quantity,
                      unit: mat.unit,
                    };
                  }
                });
                setMaterialFormData({
                  ...materialFormData,
                  material: event.target.value,
                  name: thisMaterial.name,
                  quantity: thisMaterial.quantity,
                  unit: thisMaterial.unit,
                });
              }}
            />
          </div>
          <p>Total Unit issued</p>
          <p>
            {materialFormData.material
              ? materialsDD.filter((mat) => {
                  return mat._id == materialFormData.material;
                })[0].quantity +
                " " +
                materialsDD.filter((mat) => {
                  return mat._id == materialFormData.material;
                })[0].unit
              : null}
          </p>
          <p style={{ marginTop: "30px" }}>Consumed</p>
          <div>
            <Input
              size="wide"
              onChange={(event) => {
                setMaterialFormData({
                  ...materialFormData,
                  consumed: event.target.value,
                });
              }}
            />
          </div>
        </div>
        <center>
          {!verify ? (
            <Button
              className="success"
              label="Add"
              onClick={addMaterialFunc}
            ></Button>
          ) : null}
        </center>
        <br />
        <br />
        <hr />
        <br />
        {!verify || projectDetails.state === "Completed" ? (
          <Button
            className="success"
            label="Submit"
            onClick={modalVisible}
          ></Button>
        ) : null}
        <Button
          label="Download report"
          onClick={() => {
            setCurrentProject(todayProgress);
            setShowPdf(true);
          }}
        ></Button>
      </Paper>
      {showPdf ? (
        <Paper>
          <PDFViewer height="800" width="1200" className="app">
            <TallyReportPrint
              day={currentProject}
              projectDetails={projectDetails}
            />
          </PDFViewer>
        </Paper>
      ) : null}
    </div>
  );
};

export default TallyBook;
